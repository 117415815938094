<template>
  <div class="right_box">
    <div style="justify-content: space-between" class="df aic">
      <div style="margin-bottom: 20px" class="tt df">
        <div class="suxian"></div>
        商品列表
      </div>
    </div>
    <div class="head mb30">
      <div style="margin-left: 16px" class="li pt10 boder df">
        <div
          @click="swichchange(1)"
          :class="swichActive == 1 ? 'switchactive' : ''"
          class="swich"
        >
          已匹配
        </div>
        <!-- <div
          @click="swichchange(2)"
          :class="swichActive == 2 ? 'switchactive' : ''"
          class="swich"
        >
          已匹配-价格高
        </div> -->
        <div
          @click="swichchange(3)"
          :class="swichActive == 3 ? 'switchactive' : ''"
          class="swich"
        >
          未匹配
        </div>
      </div>

      <div style="flex: 1; justify-content: flex-end" class="df">
        <!-- <div class="li cup">
          <img
            class="icon cup"
            src="@/assets/images/quicklyOrder/wenjian.png"
            alt
          />
          <span style="margin: 0 5px">一键生成订单</span>
        </div>
        <div class="su"></div> -->
        <div v-show="swichActive !== 3" @click="addCartAll" class="li cup">
          <img class="icon cup" :src="gouwuche" alt />
          <span style="margin: 0 5px">一键加入购物车</span>

          <el-popover placement="bottom-end" width="300" trigger="hover">
            <img slot="reference" class="icon cup" :src="wenhao" alt />
            <div class="df">
              <div style="color: #fff">
                <i style="color: #ffa936" class="el-icon-warning"></i>
              </div>
              <div style="margin-left: 2px">
                温馨提示：仅涉及匹配度100%的商品
              </div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="search">
      <el-form size="small" :inline="true" :model="form">
        <el-form-item label="客户商品编号">
          <el-input v-model="form.customerProductId"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="form.customerProductName"></el-input>
        </el-form-item>
        <el-form-item label="生产厂家">
          <el-input v-model="form.customerProductManu"></el-input>
        </el-form-item>
      </el-form>
      <div class="reset">
        <el-button
          @click="getRightList"
          size="small"
          class="mr10"
          type="success"
          >搜索</el-button
        >
        <el-button @click="reset" size="small">重置</el-button>
      </div>
    </div>
    <div v-show="swichActive !== 3" class="df">
      <div @click="checkAllProduct(0)" class="allBotton">
        全选所有商品
      </div>
      <div @click="checkAllProduct(1)" class="allBotton">
        取消所有商品
      </div>
    </div>

    <div v-if="rigthListCom.length == 0" style="min-height: 700px" class="none">
      <img src="@/assets/images/kongList.png" alt="" />
      <div style="color: #707070; font-size: 18px">暂无商品列表</div>
    </div>
    <div
      v-if="!isnoneGoods"
      v-loading="loading"
      element-loading-text="加载中..."
    >
      <div v-if="rigthListCom.length > 0" class="table">
        <div class="table-headers">
          <!-- 全选 -->
          <div v-show="swichActive !== 3" class="checkeAll">
            <el-checkbox
              @change="changecheckeAll"
              :value="checkeAll"
            ></el-checkbox>
          </div>
          <div
            class="header"
            :style="`max-width:${item.width}px;min-width:${item.width}px`"
            v-for="(item, index) in tableHeader"
            :key="index"
          >
            {{ item.name }}
          </div>
          <div v-show="swichActive !== 3" class="header max150">采购数量</div>
          <div v-show="swichActive === 3" class="header max80">操作</div>
        </div>

        <div
          class="table-Content"
          v-for="(item, index) in rigthListCom"
          @mouseover="onMouOverFn(item)"
          @mouseleave="CardLeaveFn(item)"
        >
          <HoverCard v-show="item.show" :item="item" :key="cardKey" />
          <div v-show="swichActive !== 3" class="check">
            <el-checkbox
              :key="item.id"
              @change="changeSelect(item)"
              v-model="item.checkState"
            ></el-checkbox>
          </div>

          <div class="content max50">{{ (index += 1) }}</div>
          <div class="content max80">
            <!-- 客户商品编码 -->
            <el-tooltip
              effect="dark"
              :content="item.customerProductId"
              placement="top"
            >
              <span class="ellipsis-one">{{ item.customerProductId }}</span>
            </el-tooltip>
          </div>
          <div class="content max80">
            <!-- 69码 -->
            <el-tooltip
              effect="dark"
              :content="item.customerProductBarcode"
              placement="top"
            >
              <span class="ellipsis-one">{{
                item.customerProductBarcode
              }}</span>
            </el-tooltip>
          </div>
          <div class="content max100">
            <!-- 国药准字号 -->
            <el-tooltip
              effect="dark"
              :content="item.customerProductApprovalNo"
              placement="top"
            >
              <span class="ellipsis-one">{{
                item.customerProductApprovalNo
              }}</span>
            </el-tooltip>
          </div>
          <div class="content max120">
            <!-- 品类名称 -->
            <el-tooltip
              effect="dark"
              :content="item.customerProductName"
              placement="top"
            >
              <span class="ellipsis-one">{{ item.customerProductName }}</span>
            </el-tooltip>
          </div>
          <div class="content max120">
            <!-- 厂家名称 -->
            <el-tooltip
              effect="dark"
              :content="item.customerProductManu"
              placement="top"
            >
              <span class="ellipsis-one">{{ item.customerProductManu }}</span>
            </el-tooltip>
          </div>
          <div class="content max80">
            <!-- 规格 -->
            <el-tooltip
              effect="dark"
              :content="item.customerProductSpec"
              placement="top"
            >
              <span class="ellipsis-one">{{ item.customerProductSpec }}</span>
            </el-tooltip>
          </div>
          <div class="content max50">
            <!-- 导入价格 -->
            <el-tooltip
              effect="dark"
              :content="`${item.customerProductPrice}`"
              placement="top"
            >
              <span class="ellipsis-one">{{ item.customerProductPrice }}</span>
            </el-tooltip>
          </div>
          <div class="content max50">
            <!-- 云集价格 -->
            <el-tooltip
              effect="dark"
              :content="`${item.mallSalePrice}`"
              placement="top"
            >
              <span class="ellipsis-one">{{ item.mallSalePrice }}</span>
            </el-tooltip>
          </div>
          <div v-show="swichActive !== 3" class="content max150">
            <!-- 采购数量 -->
            <CustomInputNumber
              @input="input(item)"
              :min="1"
              :step="1"
              :stepStrictly="true"
              v-model="item.num"
            ></CustomInputNumber>
          </div>
          <!-- 操作 -->
          <div v-show="swichActive === 3" class="content max80">
            <div @click="duiCode(item)" class="gr cup">手动对码</div>
          </div>
        </div>
      </div>

      <div class="flex-end mt30" v-if="rigthListCom.length > 0">
        <el-pagination
          class="pb20"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageNum"
          :page-size="form.pageSize"
          layout="prev, pager, next, total,jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <el-drawer
      :wrapperClosable="false"
      title="匹配该商品"
      :visible.sync="drawer"
      direction="rtl"
      size="70%"
    >
      <div class="drawer-content p20">
        <div class="ml20 df aic">
          <div style="font-size: 14px" class="mb20 df">
            <div
              style="margin-right: 5px; height: 14px; width: 3px"
              class="suxian"
            ></div>
            产品列表
          </div>
        </div>

        <div class="search-box">
          <div class="search ml30">
            <el-form
              class="formStyle"
              size="small"
              :inline="true"
              :model="CorrectCodeForm"
            >
              <el-form-item label="普药控销">
                <el-select
                  clearable
                  filterable
                  v-model="CorrectCodeForm.shopType"
                  placeholder="普药控销"
                >
                  <el-option label="普药" value="1"></el-option>
                  <el-option label="控销" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="商品名称">
                <el-input clearable v-model="CorrectCodeForm.name"></el-input>
              </el-form-item>
              <el-form-item label="平台商品编码">
                <el-input
                  clearable
                  v-model="CorrectCodeForm.erpCode"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="reset flex-end">
            <el-button
              size="small"
              @click="getCorrectCodeProductsListFn"
              class="mr10"
              type="success"
              >搜索</el-button
            >
            <el-button @click="resetCorrectCodeForm" size="small"
              >重置</el-button
            >
          </div>
        </div>

        <div class="tableList">
          <el-table
            v-loading="correctLoading"
            tooltip-effect="light"
            :data="tableData"
            style="width: 100%"
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="date" width="80">
              <template slot-scope="scope">
                <img
                  class="drawer-img"
                  :src="
                    scope.row.attachUrl.length ? scope.row.attachUrl : demoPng
                  "
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              type="index"
              label="序号"
              width="50"
            >
            </el-table-column>
            <el-table-column
              prop="erpCode"
              :show-overflow-tooltip="true"
              align="center"
              label="平台商品编码"
              width="150"
            >
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              align="center"
              prop="barcode"
              label="69码"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="approvalNo"
              width="80"
              :show-overflow-tooltip="true"
              align="center"
              label="国药准字"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              width="150"
              :show-overflow-tooltip="true"
              align="center"
              label="商品名称"
            >
            </el-table-column>
            <el-table-column
              prop="manufacturer"
              width="150"
              :show-overflow-tooltip="true"
              align="center"
              label="厂家名称"
            >
            </el-table-column>
            <el-table-column
              prop="spec"
              width="auto"
              :show-overflow-tooltip="true"
              align="center"
              label="规格"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.spec }}/{{ scope.row.unit }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="packSpec"
              width="auto"
              :show-overflow-tooltip="true"
              align="center"
              label="件装量"
            >
              <template slot-scope="scope">
                <div>{{ scope.row.packSpec }}{{ scope.row.unit }}/件</div>
              </template>
            </el-table-column>
            <el-table-column
              width="100"
              :show-overflow-tooltip="true"
              align="center"
              label="操作"
            >
              <template slot-scope="scope">
                <div class="df jfc">
                  <div @click="confirmDuiCode(scope.row)" class="goCode">
                    对码
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="flex-end mt30" v-if="tableData.length > 0">
            <el-pagination
              background
              @size-change="CorrectCodeSizeChange"
              @current-change="CorrectCodeCurrentChange"
              :current-page.sync="CorrectCodeForm.pageNum"
              :page-size="CorrectCodeForm.pageSize"
              layout="prev, pager, next, total,jumper"
              :total="productTotal"
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getCorrectCodeProductsList,
  orderManualAPI,
  checkAllAPI,
  checkOneAPI,
  reNumAPI,
  checkIds,
} from "@/request/Orders/order";
import CustomInputNumber from "./inputNumber.vue";
import HoverCard from "./hoverCard.vue";
export default {
  watch: {
    rightList: {
      immediate: true,
      handler(newVal, olVal) {
        this.rigthListCom = newVal;
        this.rigthListCom.map((item) => {
          item.checkState = item.checkState === 0 ? true : false;
        });
        this.CorrectCodeForm = {
          shopType: undefined, //商品类型1,普药 2,控销；普药即是流通药，控销即是品牌药
          pageNum: 1,
          pageSize: 10,
          name: undefined,
          erpCode: undefined,
        };
        this.form = {
          customerProductId: undefined,
          productName: undefined,
          pageNum: 1,
          pageSize: 20,
        };
      },
    },
  },
  components: {
    CustomInputNumber,
    HoverCard,
  },
  props: {
    rightList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    swichActive: {
      type: Number,
      default: () => {
        return 1;
      },
    },
    pageNum: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    total: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    loading: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isnoneGoods: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
  activated() {},
  created() {},
  data() {
    return {
      gouwuche: require("../../../assets/images/quicklyOrder/gouwuche.png"),
      wenhao: require("../../../assets/images/quicklyOrder/wenhao.png"),
      demoPng: require("../../../assets/images/kongProdact.png"),
      correctLoading: false,
      form: {
        customerProductId: undefined,
        productName: undefined,
        pageNum: 1,
        pageSize: 10,
      },
      CorrectCodeForm: {
        shopType: undefined, //商品类型1,普药 2,控销；普药即是流通药，控销即是品牌药
        pageNum: 1,
        pageSize: 10,
        name: undefined,
        erpCode: undefined,
      },
      drawer: false,
      dialogVisible: false,
      // checkeAll: true,
      tableData: [],
      tableHeader: [
        { name: "序号", width: 50 },
        { name: "客户商品编码", width: 80 },
        { name: "69码", width: 80 },
        { name: "国药准字", width: 100 },
        { name: "品类名称", width: 120 },
        { name: "厂家名称", width: 120 },
        { name: "规格", width: 80 },
        { name: "导入价格", width: 50 },
        { name: "云集价格", width: 50 },
        // { name: "采购数量", width: 150 },
        // { name: "操作", width: 80 },
      ],
      duiCodeItem: {},
      productTotal: 0,
      cardKey: 0,
      OverTimeOut: false,
    };
  },
  computed: {
    checkeAll: {
      set(v) {
        this.rightList.forEach((item) => (item.checkState = v));
      },
      get() {
        return (
          this.rightList.length ===
          this.rightList.filter((item) => item.checkState).length
        );
      },
    },
  },
  methods: {
    input(e) {
      let data = {
        id: e.id,
        num: e.num,
      };
      reNumAPI(data);
    },
    // 一键全选按钮
    checkAllProduct(type) {
      let data = {
        checkState: type,
        isPriceHigh: this.swichActive,
        orderId: this.rigthListCom[0]?.orderId,
      };
      checkAllAPI(data).then((res) => {
        if (res.code === 200)
          this.$message.success(
            type === 0 ? "已全选所有商品" : "已取消所有商品"
          );
        this.rigthListCom.map((item) => {
          item.checkState = type === 0 ? true : false;
        });
      });
    },
    //当前页全选
    changecheckeAll(e) {
      console.log(this.checkeAll, e);
      let ids = [];
      this.rigthListCom.map((item) => {
        item.checkState = e;
        ids.push(item.id);
      });
      let data = {
        checkState: e ? 0 : 1,
        isPriceHigh: this.swichActive,
        orderId: this.rigthListCom[0]?.orderId,
        ids,
      };
      checkIds(data);
    },
    //单选
    changeSelect(item) {
      // item.checkState = !item.checkState;
      let data = {
        checkState: item.checkState ? 0 : 1,
        isPriceHigh: this.swichActive,
        id: item.id,
      };
      checkOneAPI(data);
    },
    tableRowClassName({ row, rowIndex }) {
      return "warning-row";
    },
    // 确定匹配弹窗
    goToCode() {
      this.getCorrectCodeProductsListFn();
      this.drawer = true;
    },
    // 获取对码商品List
    async getCorrectCodeProductsListFn() {
      this.correctLoading = true;
      try {
        const { code, data } = await getCorrectCodeProductsList(
          this.CorrectCodeForm
        );
        if (code === 200) {
          this.productTotal = data.total;
          this.tableData = data.rows;
          this.tableData.map((item) => {
            if (item.attachUrl.length) {
              item.attachUrl =
                item.attachUrl?.split(",")?.[0] || item.attachUrl;
            }
          });
        }
      } catch (error) {
      } finally {
        this.correctLoading = false;
      }
    },
    //手动对码
    duiCode(item) {
      this.duiCodeItem = item;
      this.getCorrectCodeProductsListFn();
      this.drawer = true;
    },
    //确认手动对码
    confirmDuiCode(item) {
      let data = {
        otrherCodeOrderItemId: this.duiCodeItem.id,
        productId: item.id,
      };
      this.$confirm("是否确认是商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.correctLoading = true;
        try {
          orderManualAPI(data).then((res) => {
            if (res.code === 200) {
              this.$message.success("对码成功");
              this.drawer = false;
              this.getRightList();
            }
          });
        } catch (error) {
        } finally {
          this.correctLoading = false;
        }
      });
    },
    onMouOverFn(item) {
      if (this.swichActive !== 3) {
        item.show = true;
        this.$forceUpdate();
      }
    },
    CardLeaveFn(item) {
      if (this.swichActive !== 3) {
        item.show = false;
        this.$forceUpdate();
      }
    },
    reset() {
      this.form = {
        customerProductId: undefined,
        customerProductName: undefined,
        pageNum: 1,
        pageSize: 10,
      };
      this.$emit("getRightList", this.rigthListCom[0]?.orderId, this.form);
    },

    resetCorrectCodeForm() {
      this.CorrectCodeForm = {
        shopType: undefined, //商品类型1,普药 2,控销；普药即是流通药，控销即是品牌药
        pageNum: 1,
        pageSize: 10,
        name: undefined,
        erpCode: undefined,
      };
    },
    addCartAll() {
      this.$emit("addCartAll");
    },
    swichchange(index) {
      this.$emit("swichchange", index);
    },
    getRightList() {
      this.$emit("getRightList", this.rigthListCom[0]?.orderId, this.form);
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.this.$emit("handleSizeChange", e);
    },
    handleCurrentChange(e) {
      console.log(e);
      this.form.pageNum = e;
      this.$emit("handleCurrentChange", e);
    },
    CorrectCodeSizeChange(e) {
      this.CorrectCodeForm.pageSize = e;
      this.getCorrectCodeProductsListFn();
    },
    CorrectCodeCurrentChange(e) {
      this.CorrectCodeForm.pageNum = e;
      this.getCorrectCodeProductsListFn();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
.allBotton {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #26c487;
  border: 1px solid #26c487;
  padding: 10px 15px;
}
::v-deep {
  .el-drawer__body {
    overflow-x: hidden;
  }
  .el-table .warning-row {
    background: #f7f8fa;
    margin-bottom: 5px;
  }
}
.formStyle {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-right: 20px;
}
.goCode {
  text-align: center;
  cursor: pointer;
  width: 40px;
  color: #26c487;
  background: #ffffff;
  padding: 4px 0;
}
.drawer-img {
  width: 58px;
  height: 58px;
}
::v-deep {
  .el-drawer__header {
    background: #f7f8fa;
    color: #202d40;
    padding: 13px 40px;
  }
}
.ml30 {
  margin-left: 30px;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.drawer-content {
  ::v-deep {
    .el-select {
      width: 180px;
    }
    .el-select .el-input {
      width: 180px;
    }
  }
  .search-box {
    display: flex;
    flex-direction: column;
    .search {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
.p20 {
  padding: 20px;
}

.search {
  display: flex;
  justify-content: space-between;
}
.table {
  // max-width: 55vw;
  padding-bottom: 30px;
  // overflow-x: auto;
  // overflow-y: auto;
  .table-headers {
    padding: 10px 0;
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
    .header {
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .table-Content {
    position: relative;
    display: flex;
    justify-content: space-between;
    // width: 120%;
    padding-left: 20px;
    background: #f7f8fa;
    margin-bottom: 5px;
    .check {
      padding: 10px 0;
      display: flex;
      align-items: center;
    }
    .content {
      white-space: nowrap;
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      // &:last-child {
      //   position: sticky;
      //   right: 0;
      //   z-index: 1;
      //   width: 150px;
      //   background: #f5f5f5;
      // }
    }
  }
}

.right_box {
  background-color: #fff;
  padding: 35px 20px 0 20px;
  flex: 1;

  .head {
    display: flex;
    height: 40px;
    align-items: center;
    .boder {
      border-bottom: 1px solid #e4e4e4;
    }
    .li {
      display: flex;
      align-items: center;
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .none {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
  }
  .swich {
    margin-right: 20px;
    padding-bottom: 8px;
    line-height: 100%;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
  }
  .switchactive {
    font-size: 14px;
    font-weight: 500;
    color: #26c487;
    border-bottom: 2px solid #26c487;
  }
  .su {
    line-height: 40px;
    margin: 10px 20px;
    width: 1px;
    height: 16px;
    background: #f7f8fa;
  }
  .active {
    border: 1px solid @green !important;
  }
}
</style>
