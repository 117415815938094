<template>
  <div style="background: #eef0f2" class="kong">
    <div class="ShoppingCart">
      <div class="body">
        <div class="banxin">
          <div class="left">
            <div style="font-size: 18px" class="tt df">
              <div class="suxian"></div>
              请选择收货地址
            </div>

            <div class="ipt">
              <el-input
                placeholder="输入客户名称"
                clearable
                v-model="inputAddr"
              >
              </el-input>
            </div>

            <div class="ipt mt20">
              <el-cascader
                :show-all-levels="false"
                style="width: 100%"
                placeholder="选择地区"
                :props="{ checkStrictly: true }"
                @change="handleChange"
                :options="options"
                v-model="selectedOptions"
                clearable
              ></el-cascader>
            </div>
            <div class="btn mt20 df">
              <el-button @click="resetFn">重 置</el-button>
              <el-button @click="shearchAddr" type="success">搜 索</el-button>
            </div>

            <div class="addressList">
              <div class="ul mt20">
                <div
                  @click="changeList(index, item)"
                  v-for="(item, index) in userAddr"
                  :key="index"
                  class="li cup"
                  :class="{ 'active ': active == index }"
                >
                  <div class="top pb10 fs14 xu">
                    <div class="fhui aic">
                      <img
                        style="margin-right: 5px"
                        class="icon"
                        src="@/assets/images/cart/man.png"
                        alt
                      />
                      客户名称:
                    </div>
                    <div class="conp fs14 mt10">{{ item.name }}</div>
                  </div>

                  <div class="mt20">
                    <div class="fhui aic fs14">
                      <img
                        style="vertical-align: top; margin-right: 5px"
                        class="icon"
                        src="@/assets/images/cart/postion.png"
                        alt
                      />
                      收货地址:
                    </div>
                    <div class="conp fs14 mt10">{{ item.address }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="right">
            <div v-if="iskong" class="tac kk">
              <div>
                <img
                  style="width: 174px; height: 190px"
                  src="@/assets/images/kongCart.png"
                  alt
                />
                <div class="empty-tips">
                  您的购物车还没有商品!
                  <span @click="$router.push('/home')" class="gr cup"
                    >去逛逛</span
                  >
                </div>
              </div>
            </div>
            <div v-if="!iskong">
              <div class="downLoad df">
                <div style="font-size: 18px" class="tt df">
                  <div class="suxian"></div>
                  商品列表
                </div>

                <!-- <div class="gr cup fs14">
                  <i style="color: #26c487" class="el-icon-download"></i>
                  下载提货委托书模板
                </div>-->
              </div>
              <div class="head aic df">
                <div class="ipt" style="margin-right: 90px">
                  <el-input
                    @keyup.native.enter="inputEnter"
                    placeholder="输入商品名称或厂家名称"
                    suffix-icon="el-icon-search"
                    v-model="productinput"
                    class="search"
                  ></el-input>
                </div>
                <div class="rightx">
                  <div class="fs12">
                    <span class="fhui mr8">收货方式:</span>
                    <span class="fs12">
                      {{ receiveType == 1 ? "送货上门" : "到仓自提" }}
                    </span>
                  </div>
                  <div class="su"></div>
                  <div class="dizi">
                    <div class="fs12">
                      <img
                        class="icon"
                        style="margin-right: 8px"
                        src="@/assets/images/cart/cart.png"
                        alt
                      />
                      <span class="fhui mr8">收货信息:</span>
                      <span>{{ addName }}</span>
                    </div>
                  </div>
                  <div class="su"></div>
                  <div class="genggai df cup">
                    <img
                      class="icon"
                      style="margin-right: 4px"
                      src="@/assets/images/cart/change.png"
                      alt
                    />
                    <div @click="genggai" class="fs12">
                      更改为{{ receiveType == 1 ? "到仓自提" : "送货上门" }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-loading="loading" class="cartList">
                <div class="list">
                  <el-table
                    :key="keys"
                    :row-class-name="tableRowClassName"
                    :header-cell-style="{ background: '#F8F8F8' }"
                    ref="multipleTable"
                    :data="goodsList"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :header-row-style="{ fontWeight: 400 }"
                    @select-all="handleSelectionChange"
                    @select="handleSelectionChange"
                  >
                    <!-- //下架商品禁用状态 :selectable="checkSelectable" -->
                    <el-table-column
                      :selectable="checkSelectable"
                      type="selection"
                      width="45"
                    >
                    </el-table-column>
                    <el-table-column label="全选" align="left" width="400">
                      <template slot-scope="scope">
                        <Product-Card :isCart="true" :rows="scope.row">
                        </Product-Card>
                      </template>
                    </el-table-column>
                    <el-table-column prop="spec" label="规格" width="120">
                      <template slot-scope="scope">
                        <div style="color: #333; font-size: 12px">
                          {{ scope.row.spec }}
                        </div>
                        <!-- 议价期望价格 -->
                        <div
                          v-if="scope.row.bargainingFlag > 0"
                          style="
                            z-index: 999;
                            bottom: 23px;
                            position: absolute;
                            width: 300px;
                          "
                          class="flex-start"
                        >
                          <div class="flex-start" style="margin-right: 50px">
                            <div class="hopePrice">期望价</div>
                            <span class="apprice">{{
                              scope.row.applyPrice
                            }}</span>
                          </div>

                          <div class="flex-start">
                            <div class="afterhopePrice">议后价</div>
                            <span class="apprice">{{
                              scope.row.bargainingFlag == 2
                                ? scope.row.productPrice
                                : "--"
                            }}</span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="originalPrice"
                      align="center"
                      label="单价(元)"
                      width="auto"
                    >
                      <template slot-scope="scope">
                        <span
                          class="apprice"
                          style="color: #333; font-size: 12px"
                          >{{
                            scope.row.shopType == 2 && scope.row.priceFlag == 1
                              ? scope.row.productPrice
                              : scope.row.priceFlag == 0 &&
                                scope.row.shopType == 2
                              ? "待定价"
                              : scope.row.bargainingFlag == 2 ||
                                scope.row.bargainingFlag == 1
                              ? scope.row.originalPrice
                              : scope.row.productPrice
                          }}</span
                        >
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="productQuantity"
                      align="center"
                      label="数量"
                      width="150"
                    >
                      <template slot-scope="scope">
                        <el-input-number
                          :disabled="scope.row.status !== 'UP'"
                          :step="scope.row.minSales"
                          step-strictly
                          style="width: 100px"
                          size="small"
                          :key="scope.row.key"
                          :min="scope.row.minSales"
                          :stepStrictly="true"
                          @blur="changeBuyNum(scope.row)"
                          @change="changeBuyNum(scope.row)"
                          v-model="scope.row.productQuantity"
                        ></el-input-number>
                        <div
                          :style="
                            scope.row.status !== 'UP' ? 'color: #333' : ''
                          "
                          class="gr fs12"
                        >
                          {{
                            scope.row.shopType == 1 && scope.row.stock == 0
                              ? ""
                              : "库存"
                          }}
                          {{
                            scope.row.pchao &&
                            scope.row.shopType == 1 &&
                            scope.row.stock
                              ? scope.row.stock + "件"
                              : scope.row.shopType == 1 && scope.row.stock == 0
                              ? "代采"
                              : scope.row.stock + "件"
                          }}
                        </div>

                        <div
                          :style="
                            scope.row.stock &&
                            scope.row.productQuantity > scope.row.stock
                              ? 'color:#e33233'
                              : 'display:none'
                          "
                          class="fs12"
                        >
                          超出库存!
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      width="auto"
                      align="center"
                      prop="productPrice"
                      label="小计(元)"
                    >
                      <template slot-scope="scope">
                        <div
                          class="apprice"
                          style="color: #333; font-size: 12px"
                        >
                          {{
                            scope.row.shopType == 2 &&
                            scope.row.purPlanFlag == 1
                              ? "待定价"
                              : scope.row.shopType == 2 &&
                                scope.row.priceFlag == 1
                              ? (
                                  scope.row.productPrice *
                                  scope.row.productQuantity
                                ).toFixed(2)
                              : scope.row.shopType == 2
                              ? "待定价"
                              : (
                                  scope.row.productPrice *
                                  scope.row.productQuantity
                                ).toFixed(2)
                          }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="90">
                      <template slot-scope="scope">
                        <!-- <div class="fred cup fw600">立即支付</div> -->
                        <div
                          v-if="
                            scope.row.shopType == 1 &&
                            scope.row.activityType !== 1
                          "
                          @click="apllyPrice(scope.row)"
                          :style="
                            scope.row.bargainingFlag !== 1
                              ? 'color:#333'
                              : 'color:#CCCCD4'
                          "
                          :class="
                            scope.row.bargainingFlag !== 1
                              ? 'apllyPrice yichu cup'
                              : 'disable'
                          "
                        >
                          申请议价
                        </div>
                        <div class="df jfc">
                          <!-- <div
                            style="margin-right: 10px; color: #333"
                            class="cup"
                          >
                            收藏
                          </div>-->
                          <div
                            @click="removeGoods(scope.row)"
                            style="color: #333"
                            :class="
                              scope.row.bargainingFlag == 1
                                ? 'disable'
                                : 'cup yichu'
                            "
                          >
                            移除
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div
                    class="fo df aic"
                    style="margin-top: 20px; justify-content: space-between"
                  >
                    <div class="lef df aic">
                      <div
                        :style="
                          goodsList.length == 0
                            ? 'pointer-events:none;    cursor: no-drop'
                            : ''
                        "
                        @click="removeGoods()"
                        :class="
                          cardActive == 1
                            ? 'quxiao cup fs14 gr'
                            : 'quxiao cup fs14'
                        "
                        style="margin-left: 20px"
                      >
                        批量移除
                      </div>
                      <!-- <div
                        @click="cardActive = 2"
                        :class="
                          cardActive == 2
                            ? 'quxiao cup fs14 gr'
                            : 'quxiao cup fs14'
                        "
                        style="margin-left: 20px"
                      >
                        批量收藏
                      </div>-->
                      <div
                        :style="
                          goodsList.length == 0
                            ? 'pointer-events:none;    cursor: no-drop'
                            : ''
                        "
                        @click="clearCart"
                        style="margin-left: 20px"
                        class="'quxiao cup fs14 '"
                      >
                        批量清空购物车
                      </div>
                    </div>
                    <div class="calculate-box">
                      <div class>
                        当前客户已选中
                        <span class="fred">{{ checkNum }}</span>
                        件商品
                      </div>
                      <div class="total">
                        <span class="label">总计 :</span>
                        <CustomPrice
                          :value="totalPrice.toFixed(2)"
                          fontSize="20px"
                          :fontWeight="500"
                        ></CustomPrice>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            background: #fff;
            margin-top: 20px;
            justify-content: flex-end;
            height: 63px;
          "
        >
          <div class="footer">
            <ul class="df" style="justify-content: flex-end">
              <li style="margin-right: 10px" class="footerContent">
                <div class="yi tar fs14">
                  <!-- 已选择
                  <span class="fred">{{ this.active.length }}</span> 个客户中
                  <span class="fred">30</span> 件商品-->
                  <!-- <span style="margin-left: 12px">
                    总计:
                    <CustomPrice
                      :value="totalPrice"
                      fontSize="20px"
                      :fontWeight="500"
                    ></CustomPrice>
                  </span> -->
                </div>
                <div class="tishi fhui fs12 mt10">
                  <i style="color: #ffaa34" class="el-icon-warning"></i>
                  提示：可一次性选择多个客户的商品合并提交确认。
                </div>
              </li>
              <li
                :style="
                  goodsList.length == 0
                    ? 'pointer-events:none;    cursor: no-drop'
                    : ''
                "
                @click="goPay"
                class="jiesuan cup"
              >
                去结算
              </li>
            </ul>
          </div>
        </div>
        <!-- 相关推荐 -->
        <!-- 
        <div class="last">
          <div class="section-header">相关推荐</div>

          <div class="RecommendList">
            <RecommendList style="margin: 20px 0" :list="6" />
          </div>
        </div> -->
      </div>
      <!-- 弹框 -->
      <div class="dia">
        <el-dialog
          :title="`确认${receiveType == 1 ? '到仓自提' : '送货上门'}信息`"
          :visible.sync="dialogVisible"
          width="495px"
        >
          <div class="wenxing">
            <div style="font-weight: 500; color: #333" class="fs14">
              <i style="color: #ffaa34" class="el-icon-warning"></i>
              温馨提示
            </div>
            <div class="txt">
              提货时需携带提货人本人身份证; 如委托他人提货,
              则需带上提货委托书并加盖公章(鲜章)以及提货人本人身份证。
            </div>
          </div>
          <div class="tabel">
            <div class="xin">
              <div class="left_name">
                <span class="fred mr5">*</span> 提货人姓名
              </div>
              <div class="right_name">
                <el-input
                  clearable
                  v-model="takeName"
                  placeholder="请输入提货人姓名"
                ></el-input>
              </div>
            </div>
            <div class="xin">
              <div class="left_name">
                <span class="fred mr5">*</span> 提货人手机
              </div>
              <div class="right_name">
                <el-input
                  clearable
                  v-model="takePhone"
                  placeholder="请输入提货人手机"
                ></el-input>
              </div>
            </div>
            <div class="xin">
              <div class="left_name">
                <span class="fred mr5">*</span> 提货人身份证号
              </div>
              <div style="text-align: right" class="right_name">
                <el-input
                  clearable
                  v-model="takeIdentityNum"
                  placeholder="请输入提货人身份证号码"
                ></el-input>
              </div>
            </div>
            <!-- <div class="xin">
              <div
                style="padding-left: 15px; box-sizing: border-box"
                class="left_name"
              >
                上传提货委托书
              </div>
              <div style="width: 200px" class="right_name">
                <el-upload
                  style="text-align: right"
                  :headers="config"
                  class="upload-demo"
                  accept="all"
             
                  :action="uploadUrl"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :on-success="uploadFileSuccess"
                  :before-remove="beforeRemove"
                  multiple
                  :limit="3"
                  :on-exceed="handleExceed"
                  :file-list="fileList"
                >
                  <el-button size="small" type="success">选择文件</el-button>
                </el-upload>
              </div>
            </div>-->
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button style="margin-right: 10px" @click="dialogVisible = false"
              >取 消</el-button
            >
            <el-button type="success" @click="zitiFn">确 定</el-button>
          </div>
        </el-dialog>
      </div>
      <!-- 议价弹窗 -->
      <div class="dia">
        <el-dialog
          title="申请议价"
          width="360px"
          :visible.sync="applyPriceDialog"
        >
          <div class="cartList">
            <div class="goodsDetail">
              <div class="h1">
                {{ applyList.productName }}
              </div>
              <div class="info df">
                <div style="min-width: 36px">
                  厂家： {{ applyList.manufacturer }}
                </div>
              </div>
              <div class="info df">规格： {{ applyList.spec }}</div>
              <div class="info df">售价： {{ applyList.originalPrice }}</div>
            </div>
          </div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item prop="hopePrice" label="输入期望价格">
              <el-input-number
                :controls="false"
                :precision="2"
                :max="9000000"
                placeholder="请输入您的期望价格"
                autocomplete="off"
                v-model="ruleForm.hopePrice"
              ></el-input-number>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="applyPriceDialog = false">取 消</el-button>
            <el-button type="primary" @click="PutApply">提交申请</el-button>
          </div>
        </el-dialog>
      </div>
      <!-- 提示弹窗 -->
      <div class="toast">
        <el-dialog :visible.sync="showToast" width="410px">
          <div class="flex-between">
            <div class="df aic">
              <img
                style="margin-right: 15px; width: 18px; height: 18px"
                src="@/assets/images/details/addCart.png"
                alt=""
              />
              议价申请已提交，请等待结果
            </div>
            <i
              @click="showToast = false"
              class="cup el-dialog__close el-icon el-icon-close"
            ></i>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import RecommendList from "@/components/Recommend/recommend.vue";
import { regionData, CodeToText } from "element-china-area-data";
import { shoppingMall } from "@/request/users/users";
import { updateReceiveEntrust } from "@/request/Orders/order";
import CustomPrice from "@/components/custom-base/custom-price";
import {
  findProductList,
  updateCart,
  deleteCartItemIos,
  UpdateSelFlag,
  applyBargaining,
} from "@/request/CartApi/cart";
import ProductCard from "@/components/custom-base/custom-product-card";
import { number } from "mathjs";
export default {
  components: {
    ProductCard,
    RecommendList,
    CustomPrice,
  },
  created() {
    this.token = sessionStorage.getItem("token");
    this.getAddr();
    this.fetchList();
    this.allPrice();
  },
  watch: {},
  data() {
    return {
      keys: 0,
      loading: false,
      rules: {
        hopePrice: [
          { required: true, message: "请输入您的期望价格", trigger: "blur" },
          // {
          //   pattern:
          //   /^(0|-[1-9][0-9]*)$/,
          //   message: "申请价格不合规范,可保留两位小数",
          //   trigger: "blur"
          // },
          {
            pattern: /^(0\.?\d{0,2}|[1-9]\d*\.?\d{0,2})$/,
            message: "请输入大于0的价格,可保留两位小数",
          },
        ],
      },
      //申请议价商品信息
      applyList: {},
      //期望价格
      ruleForm: {
        hopePrice: undefined,
      },
      //申请议价弹窗
      applyPriceDialog: false,
      fistCustom: 1,
      //多客户重复点击
      customerIdArr: [],
      //多客户下单
      usersList: [],
      uploadUrl:
        process.env.VUE_APP_BASE_URL + "oss/mall/sys/oss/tinymce/upload",
      token: "",
      //客户地址
      userAddr: [],
      iskong: false,
      totalPrice: 0,
      checkNum: 0,
      fileList: [],
      dialogVisible: false,
      takePhone: "",
      takeName: "",
      takeIdentityNum: "",
      takeEntrust: "",
      receiveType: "1",
      options: regionData,
      selectedOptions: [],
      selectedOptionNames: null,
      active: 0,
      cardActive: "0",
      addName: "",
      //搜索客户地址名称
      inputAddr: "",
      //商品列表
      goodsList: [],
      //客户id
      customerId: this.$store.state.AddrId,
      // 输入商品名称或厂家名称
      productinput: null,
      //批量操作 商品Id
      cartItemId: [],
      //购物车ids
      cartIds: "",
      //当前选中数据
      selectList: [],
      showToast: false,
    };
  },
  computed: {
    config() {
      return { Authorization: "bearer " + this.token };
    },
  },
  methods: {
    oninput(num) {
      var str = num;
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换
      str = str.replace(/[^\d^\.]+/g, ""); // 保留数字和小数点
      str = str.replace(/\.\d\d\d$/, ""); // 小数点后只能输两位
      return str;
    },
    //提交议价申请
    PutApply() {
      if (this.ruleForm.hopePrice > 0) {
        let row = this.applyList;
        let applyData = {
          cartId: this.cartIds,
          customerAddrId: this.customerId,
          itemList: [
            {
              applyPrice: Number(this.ruleForm.hopePrice), //期望价
              cartItemId: row.id, //购物车明细Id
              price: row.originalPrice, //商品价格,
              productId: row.productId, //商品id
            },
          ],
        };

        this.showToast = true;
        applyBargaining(applyData).then((res) => {
          if (res.code === 200) {
            this.applyPriceDialog = false;
            this.$message.success("已提交议价申请");
            this.fetchList();
          }
          this.applyPriceDialog = false;
          this.showToast = false;
        });
      }

      this.ruleForm.hopePrice = undefined;
    },
    //申请议价
    apllyPrice(row) {
      if (row.bargainingFlag !== 1) {
        this.applyList = row;
        this.applyPriceDialog = true;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status !== "UP") {
        return "mask";
      }
      return "";
    },
    //上传文件
    uploadFileSuccess(response, file, fileList) {
      //提货委托书接口
      updateReceiveEntrust({
        orderId: this.cartIds,
        takeEntrust: response.location,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("上传成功");
        }
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    beixuan(item) {
      let itemList = [
        {
          itemId: item.id,
          selFlag: item.cheked ? 1 : 0,
        },
      ];
      UpdateSelFlag({ itemList }).then((res) => {
        if (res.code !== 200) {
          item.cheked = !item.cheked;
        }
      });
    },
    //确认到仓自提
    zitiFn() {
      if (this.takeName.trim() == "") {
        this.$message.warning("请填写提货人姓名");
        return;
      }
      if (this.takePhone.trim() == "") {
        this.$message.warning("请填写请输入提货人手机");
        return;
      }
      if (this.takeIdentityNum.trim() == "") {
        this.$message.warning("请填写提货人身份证号码");
        return;
      }
      let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.takePhone)) {
        this.$message.warning("请输入正确的手机号");
        return;
      }
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (reg.test(this.takeIdentityNum) === false) {
        this.$message.warning("请输入正确的身份证号");
        return;
      }

      if (this.receiveType == 1) {
        this.usersList[this.active].takeName = this.takeName;
        this.usersList[this.active].receiveType = this.receiveType;
        this.usersList[this.active].takeEntrust = this.takeEntrust;
        this.usersList[this.active].takeIdentityNum = this.takeIdentityNum;
        this.usersList[this.active].takePhone = this.takePhone;
        this.usersList[this.active].receiveType = 2;
        this.receiveType = 2;
      } else {
        // this.takeName = "";
        // this.takePhone = "";
        // this.takeIdentityNum = "";
        this.receiveType = 1;
      }
      this.dialogVisible = false;
    },
    //清空购物车
    clearCart() {
      this.$confirm("是否确认清空购物车?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let arrid = [];
        this.goodsList.map((item) => {
          if (item.bargainingFlag !== 1) {
            arrid.push(item.id);
          }
        });

        deleteCartItemIos({ cartItemId: arrid }).then((res) => {
          if (res.code === 200) {
            this.fetchList();
            this.$message({
              type: "success",
              message: "移除成功",
            });
          }
        });
      });
    },
    //移除商品
    removeGoods(item) {
      if (item && item.bargainingFlag == 1) {
        return;
      }
      this.$confirm("是否确认移除商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (item) {
          let arrid = [];
          arrid.push(item.id);
          deleteCartItemIos({ cartItemId: arrid }).then((res) => {
            if (res.code === 200) {
              this.fetchList();
              this.$message({
                type: "success",
                message: "移除成功",
              });
            }
          });
        } else {
          if (this.selectList.length > 0) {
            this.selectList.map((item) => {
              this.cartItemId.push(item.id);
            });
            deleteCartItemIos({ cartItemId: this.cartItemId }).then((res) => {
              if (res.code === 200) {
                this.cartItemId = [];
                this.fetchList();
                this.$message({
                  type: "success",
                  message: "移除成功",
                });
              }
            });
          } else {
            this.$message({
              message: "请选择您要移除的商品",
              type: "error",
            });
          }
        }
      });
    },
    //获取列表
    fetchList(remove) {
      this.loading = true;
      findProductList({
        customerId: this.customerId || this.$store.state.AddrId,
        key: this.productinput,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          let productNum = res.data.productList.length;
          this.$store.commit("setProductNum", productNum);
          if (res.data.productList == 0 && remove == "enter") {
            this.$message.warning("搜索结果为空");
            return;
          }
          if (res.data.productList == 0) {
            this.iskong = true;
            return;
          }
          this.iskong = false;
          res.data.productList.map((item) => {
            item.cheked = item.selFlag === 1;
            return item;
          });
          this.goodsList = res.data.productList;
          this.selectList = [];
          //如果商品下架,取消选择
          let itemList = [];

          this.goodsList.map((it) => {
            if (it.selFlag === 1) {
              this.selectList.push(it);
            }
            if (it.status !== "UP") {
              itemList.push({
                itemId: it.id,
                selFlag: 0,
              });
            }
            if (it.bargainingFlag == 1) {
              itemList.push({
                itemId: it.id,
                selFlag: 0,
              });
            }
          });

          if (
            this.goodsList.some((sm) => {
              return sm.status !== "UP";
            }) ||
            this.goodsList.some((sm) => {
              return sm.bargainingFlag == 1;
            })
          ) {
            UpdateSelFlag({ itemList }).then((res) => {});
          }
          this.allPrice();
          this.toggleSelection(this.selectList);
          this.cartIds = res.data.cartId;
          if (
            this.customerIdArr.indexOf(res.data.cartId + "") === -1 &&
            this.goodsList.some((item) => {
              return item.selFlag === 1;
            })
          ) {
            this.customerIdArr.push(res.data.cartId + "");
            if (res.data.cartId) {
              let data = {
                cartId: res.data.cartId,
                // takeName: this.takeName,
                receiveType: this.receiveType,
                // takeEntrust: this.takeEntrust || "",
                // takeIdentityNum: this.takeIdentityNum,
                // takePhone: this.takePhone,
              };
              this.usersList.push(data);
            }
          }
          this.customerIdArr.push(res.data.cartId + "");
        } else {
          this.loading = false;
          this.iskong = true;
        }
        setTimeout(() => {
          if ((this.loading = true)) {
            this.loading = false;
          }
        }, 15000);
      });
    },
    //重置按钮
    resetFn() {
      this.inputAddr = null;
      this.selectedOptionNames = null;
      this.selectedOptions = [];
    },
    //搜索收货地址
    shearchAddr() {
      this.getAddr();
    },
    //获取用户收货地址
    getAddr() {
      shoppingMall({
        address: this.selectedOptionNames,
        name: this.inputAddr,
      }).then((res) => {
        if (res.code === 200) {
          this.userAddr = res.data;
          if (res.data.length > 0) {
            this.customerId = res.data[0].id;
            this.addName = res.data[0].name;
          }
        }
      });
    },
    allPrice() {
      let totalPrice1 = 0;
      this.selectList.forEach((item) => {
        totalPrice1 += item.productPrice * item.productQuantity;
      });
      this.totalPrice = totalPrice1;
      this.checkNum = this.selectList.length;
    },
    changeBuyNum(row) {
      if (row.productQuantity % row.minSales !== 0) {
        row.productQuantity =
          row.minSales * Math.floor(row.productQuantity / row.minSales);
        this.keys++;
      }
      if (
        (row.pchao && row.productQuantity > row.stock) ||
        (row.shopType == 2 && row.productQuantity > row.stock)
      ) {
        this.$message.warning("购买数量不能大于库存");
        this.$nextTick(() => {
          row.productQuantity =
            row.minSales * Math.floor(row.stock / row.minSales);
        });
        return;
      }

      updateCart({
        cartId: row.cartId,
        itemId: row.id,
        productQuantity: row.productQuantity,
      }).then((res) => {});
      this.allPrice();
    },
    goPay() {
      //     "cartId": 0,购物车id
      // "receiveType": "string",收货方式 1-送货上门 2- 到仓自提
      // "takeEntrust": "string",提货委托书
      // "takeIdentityNum": "string",提货人身份证号码
      // "takeName": "string",提货人
      // "takePhone": "string"提货人电话
      //清除数组中为empty

      let data = [];
      let arrId = [];
      if (this.usersList.length > 1) {
        for (var item of this.usersList) {
          if (item && arrId.indexOf(item["cartId"]) == -1) {
            arrId.push(item["cartId"]);
            data.push(item);
          }
        }
      } else {
        data = this.usersList;
      }
      if (this.selectList.length <= 0) {
        this.$message.warning("请选择您要购买的商品");
        return;
      }

      this.$router.push({
        name: "ConfrimShopping",
        params: {
          data,
        },
      });
      this.usersList = [];
    },
    genggai() {
      if (this.receiveType == 1) {
        // this.dialogVisible = true;
        this.receiveType = 2;
        this.usersList[this.active].receiveType = 2;
      } else {
        this.receiveType = 1;
        this.usersList[this.active].receiveType = 1;
        // this.takeName = "";
        // this.takePhone = "";
        // this.takeIdentityNum = "";
      }
    },
    //下架商品禁止选择
    checkSelectable(row) {
      //异常商品
      if (row.errorFlag !== 0) {
        return false;
      }
      //议价中商品
      if (row.bargainingFlag === 1) {
        return false;
      }
      //价格为0禁用
      if (row.shopType != 2 && row.productPrice == 0) {
        return false;
      }
      //原价格为0禁用
      if (row.originalPrice == 0) {
        return false;
      }
      //库存异常
      if (row.stockErrorFlag == 1) {
        return false;
      }
      // //控销商品价格是否为0
      // if (row.priceFlag == 1) {
      //   return false;
      // }
      if (row.status == "UP") {
        return true;
      }
    },
    //取两个数组中没有重复的
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    //全选
    handleSelectionChange(val) {
      this.selectList = val;
      let newArr = this.getArrDifference(this.goodsList, val);
      let itemList = [];
      if (val.length > 0) {
        val.map((item) => {
          item.cheked = true;
          itemList.push({
            itemId: item.id,
            selFlag: 1,
          });
        });
        newArr.map((item) => {
          item.cheked = false;
          itemList.push({
            itemId: item.id,
            selFlag: 0,
          });
        });

        let data = {
          cartId: this.cartIds,
          receiveType: this.receiveType,
        };

        this.usersList[this.active] = data;
      } else {
        this.goodsList.map((item) => {
          item.cheked = false;
          itemList.push({
            itemId: item.id,
            selFlag: 0,
          });
        });
        this.usersList.splice(this.active, 1);
      }
      UpdateSelFlag({ itemList }).then((res) => {});
      this.allPrice();
    },

    //侧边栏切换客户
    async changeList(index, item) {
      this.active = index;
      this.receiveType = this.usersList[this.active]?.receiveType || 1;
      this.addName = item.name;
      this.customerId = item.id;
      await this.fetchList();
    },
    //搜索商品
    inputEnter() {
      this.fetchList("enter");
      this.productinput = "";
    },
    handleChange(value) {
      //我们选择地址后，selectedOptions 会保存对应的区域码，例如北京的区域码为'110000'
      //我们要取出区域码对应的汉字，就需要用CodeToText(区域码)进行输出
      this.selectedOptions = value;
      var name = "";
      this.selectedOptions.map((item) => (name = CodeToText[item]));
      var names = name;
      this.selectedOptionNames = names;
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/base.less";
::v-deep {
  .el-table__row:hover > td {
    background-color: #ffffff !important;
  }

  .el-table__row--striped:hover > td {
    background-color: #fafafa !important;
  }
  .el-input-number__decrease {
    left: 1px;
    border-radius: 4px 0 0 4px;
    border-right: 0;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 4px;
    text-align: center;
    border: 1px #f5f5f5 solid;
    background: #fff;

    cursor: pointer;
    font-size: 13px;
  }
  .el-input-number--small .el-input__inner {
    border: 0;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
  }
  .el-input-number__increase {
    right: 1px;
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }
  .is-leaf el-table__cell {
    font-size: 12px;
  }

  .el-input__suffix {
    display: flex;
    right: 5px;
    transition: all 0.3s;
    pointer-events: none;
    align-items: center;
  }
  .el-button--default {
    background-color: transparent !important;
    border-bottom: 1px solid @hui;
  }
  [data-v-30cd2285] .el-input-group__append,
  [data-v-30cd2285] .el-input-group__prepend {
    background-color: #ffff;
  }
  .el-dialog__header {
    padding: 20px 34px 10px;
  }
  .el-dialog__footer {
    padding: 10px 30px 40px;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-button {
    border-radius: 0;
  }
  .el-input-group__append,
  .el-input-group__prepend {
    color: #909399;
    vertical-align: middle;
    display: table-cell;
    position: relative;
    padding: 0 20px;
    width: 1px;
    white-space: nowrap;
  }
  .el-input-group__append button.el-button,
  .el-input-group__append div.el-select .el-input__inner,
  .el-input-group__append div.el-select:hover .el-input__inner,
  .el-input-group__prepend button.el-button,
  .el-input-group__prepend div.el-select .el-input__inner,
  .el-input-group__prepend div.el-select:hover .el-input__inner {
    border-color: transparent;
    color: inherit;
    border-top: 0;
    border-bottom: 0;
  }
  .el-table .mask {
    background-color: #f9fafb;
  }
  .toast {
    .el-dialog__body {
      border-left: 3px solid #26c487;
      padding: 25px;
    }
    .el-dialog__header {
      display: none;
    }
  }
  .dia {
    .el-dialog__title::before {
      width: 2px;
      height: 18px;
      background: #26c487;
      border-radius: 2px;
      opacity: 1;
      margin-right: 5px;
    }
    .el-input__inner {
      height: 36px;
      line-height: 36px;
    }
    .el-form-item__content {
      display: flex;
      align-items: center;
      .el-form-item {
        margin-bottom: 0;
      }
    }
    .el-dialog__header {
      background: #f7f8fa;
      padding: 20px;
    }
    .el-dialog__body {
      padding: 20px 20px 0;
    }
    .el-dialog__footer {
      padding: 20px;
    }
  }
  .el-table .el-table__cell {
    padding: 20px 0 25px 0;
    vertical-align: top;
  }
  .el-table .el-table__cell:first-child {
    vertical-align: middle;
  }
}
.yichu:hover {
  color: @green !important;
}
.disable {
  color: #ccccd4 !important;
  user-select: none;
  cursor: not-allowed;
}
.afterhopePrice {
  height: 18px;
  line-height: 18px;
  margin-right: 7px;
  padding: 0 10px;
  background: #fff8f0;
  border-radius: 2px;
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #ffa936;
}
.hopePrice {
  height: 18px;
  line-height: 18px;
  margin-right: 7px;
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #26c487;
  padding: 0 10px;
  background: #eefbf6;
  border-radius: 2px;
}
.apprice {
  height: 18px;
  font-size: 12px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #333333;
}

.applyCell {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  padding-top: 15px;
  position: absolute;
  width: 905px;
  border-top: 1px solid #f5f5f5;
  z-index: 999;
  .hope {
    font-size: 12px;
    font-family: 'regular';
    font-weight: 400;
    color: #333333;
  }
}
.apllyPrice {
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #333;
}
.cartList {
  padding: 20px 18px;
  background: #f7f8fa;
  text-align: left;
  margin-bottom: 20px;
  .goodsDetail {
    flex: 1;
    line-height: 1.5;
    color: #131313;
    .h1 {
      font-size: 14px;
      margin-bottom: 15px;
      font-family: 'medium';
      font-weight: 500;
      color: #131313;
    }
    .info {
      text-align: left;
      margin-top: 5px;
      flex: 0 0 120px;
      white-space: pre-wrap;
      font-size: 12px;
      font-family: 'regular';
      font-weight: 400;
      color: #9e9fab;
    }
  }
}

.suxian {
  margin-right: 14px;
  width: 5px;
  border-radius: 2px;
  height: 20px;
}
.kk {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 1000px;
  height: 500px;
  background: #ffffff;
  border-radius: 0;
  opacity: 1;
}

.tabel {
  padding: 0 30px;
  .xin {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: center;
    margin-right: 20px;
    align-items: center;
    width: 100%;
    display: flex;
    .left_name {
      text-align: right;
      margin-right: 20px;
      font-weight: 600;
      width: 30%;
    }
    ::v-deep {
      .el-input__inner {
        width: 300px;
      }
    }
  }
}

.wenxing {
  box-sizing: border-box;
  height: 92px;
  background: #fffae5;
  padding: 15px 30px;
  border-bottom: 1px solid #ffe29f;
  border-top: 1px solid #ffe29f;
}
.dia {
  .txt {
    color: #707070;
    margin-top: 7px;
    line-height: 1.5;
    font-size: 12px;
    font-family: 'regular';
    font-weight: 400;
  }
}
.head {
  justify-content: space-between;
  margin-bottom: 20px;
  .search {
    width: 230px;
  }
  .rightx {
    display: flex;
    align-items: center;
    display: flex;
    white-space: nowrap;
    .su {
      margin: 0 10px;
    }
  }
}
.downLoad {
  justify-content: space-between;
}

.su {
  line-height: 22px;
  margin: 0 5px;
  width: 1px;
  height: 20px;
  background: @hui;
  opacity: 1;
  border-radius: 1px;
}
.body {
  padding-top: 40px;
  background-color: @body;
}
.banxin {
  display: flex;
  .right {
    box-sizing: border-box;
    padding: 34px;
    background-color: #fff;
    max-height: 800px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .left {
    box-sizing: border-box;
    padding: 33px 20px 20px 20px;
    background: #fff;
    border-right: 1px solid #f5f5f5;
    margin-right: 20px;
    width: 310px;
    max-height: 800px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .btn {
      justify-content: flex-end;
    }

    .addressList {
      width: 100%;
      .ul {
        .li {
          box-sizing: border-box;
          margin-bottom: 20px;
          border: 1px solid @hui;
          padding: 15px 18px;
          .xu {
            border-bottom: 1px dashed @hui;
          }
          .conp {
            line-height: 1.5;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
          }
        }
        .active {
          background: #f4fffb;
          border: 1px solid @green;
        }
      }
    }
  }
}
.kk {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
}
.footer {
  ul {
    align-items: center;
  }
}
.RecommendList {
  background-color: #fff;
}
.last {
  box-sizing: border-box;
  padding: 40px 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.jiesuan {
  text-align: center;
  background-color: #26c487;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  width: 114px;
  height: 63px;
  line-height: 63px;
  background: #26c487;
  opacity: 1;
}
.calculate-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.5;
  .total {
    display: flex;
    align-items: center;
    margin-left: 20px;
    .label {
      padding-right: 8px;
    }
  }
}
</style>
