<template>
  <div class="left_box">
    <div class="serch">
      <div style="padding: 0" class="flex-between">
        <div class="tt df">
          <div class="suxian"></div>
          历史记录
        </div>
        <div
          @click="addnewEXL"
          class="cup"
          style="padding: 5px 10px; border: 1px solid #f5f5f5; margin-bottom: 32px"
        >
          <img
            class="icon"
            style="margin-right: 8px"
            src="@/assets/images/quicklyOrder/xinzheng.png"
          />
          导 入
        </div>
      </div>

      <el-input
        @keyup.native.enter="search"
        style="width: 100%"
        placeholder="请输入文件名称"
        v-model="serchinput"
        class="input-with-select"
        suffix-icon="el-icon-search"
      ></el-input>
    </div>
    <div class="xls_list">
      <li
        class="cup"
        @click="changeList(item, index)"
        v-for="(item, index) in leftList"
        :key="index"
        :class="{ 'active ': active == item.id }"
      >
        <div class="cont">
          <div class="df aic fs14">
            <img
              class="icon"
              style="margin-right: 5px"
              src="@/assets/images/quikimg.png"
              alt
            />
            {{ item.fileName }}
          </div>
          <div class="wenzi fs12 fhui">
            商品总数：
            <span v-if="item.totalNum == 0">正在统计...</span>

            <span v-if="item.totalNum != 0">{{ item.totalNum }}</span>
          </div>
          <div class="wenzi fs12 fhui">
            更新时间：{{ item.modifyTime || "暂无" }}
          </div>
          <div class="wenzi fs12 fhui">
            上传时间：{{ item.createTime || "暂无" }}
          </div>
        </div>
      </li>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    active: {
      type: String || Number,
      default: () => {
        return "0";
      },
    },
  },
  data() {
    return {
      serchinput: "",
      actived: 0,
    };
  },
  methods: {
    search() {
      this.$emit("search", this.serchinput);
    },
    changeList(item, index) {
      this.$emit("changeList", item, index);
    },
    addnewEXL() {
      this.$emit("addnewEXL");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
.active {
  border: 1px solid @green !important;
}
.left_box {
  // overflow-y: auto;
  height: 100%;
  padding: 30px 22px;
  background-color: #fff;
  box-sizing: border-box;
  border-right: 1px solid @hui;
  margin-right: 20px;
  width: 287px;
  .xls_list {
    margin-top: 20px;
    li {
      padding: 20px;
      border: 1px solid @hui;
      border-radius: 2px;
      /* width: calc(100% - 10px); */
      margin-bottom: 20px;
    }

    .fs18 {
      margin-bottom: 16px;
    }
    .cont {
      word-break: break-all;
      .wenzi {
        margin: 8px 0;
      }
    }
  }
}
</style>
