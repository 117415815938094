<template>
  <div
    v-loading="buyloading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    class="body"
  >
    <div class="banxin">
      <div v-if="addnewStatus">
        <div class="flex-between">
          <div style="margin-bottom: 0" class="tt df">
            <div class="suxian"></div>
            导入商品清单
          </div>
          <div
            @click="addnewStatus = false"
            v-if="fromNew"
            class="gr cup flex-start fs14"
          >
            <img
              class="mr5"
              src="@/assets/images/quicklyOrder/timer.png"
              alt=""
            />
            你的导入历史
          </div>
        </div>

        <div class="upload">
          <el-upload
            class="upload-demo"
            drag
            :headers="config"
            :before-upload="beforeUpload"
            :http-request="uploadFile"
            :action="uploadUrl"
            multiple
          >
            <img
              class="icon"
              src="@/assets/images/quicklyOrder/upload.png"
              alt=""
            />
            <div class="el-upload__text uploadbtn mt20">选择文件</div>
            <div class="el-upload__text mt20 fs14" style="color: #adafad">
              请拖动您的商品清单文件
            </div>

            <div class="el-upload__text mt20 fs14" style="color: #adafad">
              （仅支持.xlsx和.xls格式，且文件大小不超过10M）到此虚线区域
            </div>
          </el-upload>
        </div>
      </div>

      <div v-else class="list">
        <div v-if="isnone" class="none">暂无历史记录</div>
        <div v-if="!isnone" class="left_box">
          <LeftListCom
            :leftList="leftList"
            @addnewEXL="addnewEXL"
            @changeList="changeList"
            @search="search"
            :active="active"
          />
        </div>

        <div v-if="!isnone" class="right_box">
          <RightListCom
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            @swichchange="swichchange"
            @addCartAll="addCartAll"
            @getRightList="getRightList"
            :pageNum="pageNum"
            :rightList="rightList"
            :isnoneGoods="isnoneGoods"
            :loading="loading"
            :swichActive="swichActive"
            :total="total"
            :key="RightListComKey"
          />
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <div class="dia">
      <el-dialog
        title="确认表头信息"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="495px"
      >
        <div class="tabel">
          <div
            class="xin bd"
            style="height: 40px; line-height: 40px; width: 100%"
          >
            <div
              class="left_name tac"
              style="background-color: #f8f8f8; margin-left: 0"
            >
              平台表头信息
            </div>
            <div style="background-color: #f8f8f8" class="right_name fl">
              <span style="color: #adafad; margin-left: 10px"
                >您当前上传的表头信息</span
              >
            </div>
          </div>
          <div class="biao">
            <div class="xin">
              <div class="left_name">
                <span class="red">*</span> 客户商品编码
              </div>
              <div class="right_name">
                <el-select
                  clearable
                  filterable
                  v-model="comfrimFrom.customerProductId"
                  placeholder="客户商品编码"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="xin">
              <div class="left_name">69码</div>
              <div class="right_name">
                <el-select
                  clearable
                  filterable
                  v-model="comfrimFrom.customerProductBarcode"
                  placeholder="69编码"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="xin">
              <div class="left_name">国药准字</div>
              <div class="right_name">
                <el-select
                  clearable
                  filterable
                  v-model="comfrimFrom.customerProductApprovalNo"
                  placeholder="国药准字号"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="xin">
              <div class="left_name">品类名称</div>
              <div class="right_name">
                <el-select
                  clearable
                  filterable
                  v-model="comfrimFrom.customerProductName"
                  placeholder="商品名称"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="xin">
              <div class="left_name">厂家名称</div>
              <div class="right_name">
                <el-select
                  clearable
                  filterable
                  v-model="comfrimFrom.customerProductManu"
                  placeholder="生产厂家"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="xin">
              <div class="left_name">规格</div>
              <div class="right_name">
                <el-select
                  clearable
                  filterable
                  v-model="comfrimFrom.customerProductSpec"
                  placeholder="规格"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="xin">
              <div class="left_name"><span class="red">*</span> 价格</div>
              <div class="right_name">
                <el-select
                  clearable
                  filterable
                  v-model="comfrimFrom.price"
                  placeholder="单价"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <div class="xin">
              <div class="left_name"><span class="red">*</span>采购数量</div>
              <div class="right_name">
                <el-select
                  clearable
                  filterable
                  v-model="comfrimFrom.num"
                  placeholder="数量"
                >
                  <el-option
                    clearble
                    v-for="item in options"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button style="margin-right: 10px" @click="dialogVisible = false"
            >取消上传</el-button
          >
          <el-button :loading="loading" @click="startMa" type="success"
            >开始对码</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx/xlsx.mjs";
import { addCartApi, quickOrder } from "@/request/goods/good";
import RightListCom from "./components/rightList.vue";
import LeftListCom from "./components/leftList.vue";
import demoPng from "@/assets/images/kongProdact.png";
import {
  uploadAPI,
  otrherCodeAPI,
  orderListAPI,
  productListAPI,
  oneKeyShopAPI,
  //
  del,
  goodsHeaderInfo,
  downLoadGoodBase,
  goodsHeaderPage,
  uploadCertain,
  manMade,
  cancelExcel,
  goodsBasePage,
} from "@/request/Orders/order";
export default {
  components: { RightListCom, LeftListCom },
  data() {
    return {
      Reg: 0,
      demoPng,
      uploadFileList: {
        address: {
          address: undefined,
          cusAddrId: undefined,
          customerCode: undefined,
          customerName: undefined,
          typed: undefined,
        },
        baseType: 2,
        customerType: 1, ///
        fileId: null,
        fileName: undefined,
        fileType: 1,
        fileUrl: undefined,
        productList: [
          {
            customerProductApprovalNo: undefined,
            customerProductBarcode: undefined,
            customerProductId: undefined,
            customerProductManu: undefined,
            customerProductName: undefined,
            customerProductSpec: undefined,
          },
        ],
      },
      buyloading: false,
      nowTime: "",
      //定时器
      interval: "",
      //导出文件文件名
      fileName: "",
      // uploadUrl: process.env.VUE_APP_BASE_API + "oss/admin/sys/oss/upload",
      uploadUrl: process.env.VUE_APP_BASE_URL + "oss/admin/sys/oss/upload",
      donwnLoadUrl:
        process.env.VUE_APP_BASE_URL +
        "other-code/mall/goodsHeader/downLoadGoodBase",
      showtoast: false,
      risePrice: require("@/assets/images/quicklyOrder/shang.png"),
      reducedPrice: require("@/assets/images/quicklyOrder/xia.png"),
      //新增导入状态
      addnewStatus: false,
      fromNew: false,
      loading: false,
      swichActive: 1, //1全部,2完全匹配,3待确定
      duimaList: [],
      //左边列表
      leftList: [],
      //右边商品列表
      rightList: [],
      rightProductId: "",
      isnone: false,
      isnoneGoods: false,
      dialogVisible: false,
      duimaid: "",
      comfrimFrom: {
        customerProductId: null, //erpid
        customerProductBarcode: null, //69码
        customerProductApprovalNo: null, //国药准字
        customerProductName: null, //品类名称
        customerProductManu: null, //厂家名称
        customerProductSpec: null, //规格
        price: null, //价格
        num: null, //数量
      },
      options: [],
      serchinput: "",
      active: "0",
      priceNum: 0,
      total: 0,
      pageSize: 20,
      pageNum: 1,
      token: "",
      RightListComKey: 0,
    };
  },
  created() {
    let address = JSON.parse(localStorage.getItem("customerList"));
    this.uploadFileList.address.address = address.address;
    this.uploadFileList.address.customerName = address.name;
    this.uploadFileList.address.cusAddrId = address.id;
    this.uploadFileList.address.customerCode = address.addressCode;
    this.uploadFileList.address.typed = address.typed;

    this.token = sessionStorage.getItem("token");
    this.getList();
  },
  beforeDestroy() {
    // clearInterval(this.interval);
  },
  computed: {
    config() {
      return { Authorization: "bearer " + this.token };
    },
  },

  mounted() {},
  methods: {
    startMa() {
      if (!this.comfrimFrom.customerProductId) {
        this.$message.error("请选择商品编码");
        return;
      }
      if (!this.comfrimFrom.price) {
        this.$message.error("请选择价格");
        return;
      }
      if (!this.comfrimFrom.num) {
        this.$message.error("请选择采购数量");
        return;
      }
      this.loading = true;
      this.confirmFn();
      if (this.Reg !== 0) {
        otrherCodeAPI(this.uploadFileList).then((res) => {
          (this.comfrimFrom = {
            customerProductId: null, //erpid
            customerProductBarcode: null, //69码
            customerProductApprovalNo: null, //国药准字
            customerProductName: null, //品类名称
            customerProductManu: null, //厂家名称
            customerProductSpec: null, //规格
            price: null, //价格
            num: null, //数量
          }),
            (this.dialogVisible = false);
          this.getList();
        });
      }
      this.loading = false;
    },
    regFn() {
      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      this.uploadFileList.productList.some((item) => {
        if (reg.test(item.price)) {
          this.Reg = 0;
          this.$message.error(
            `${item.customerProductName},价格表头内容包含中文无法解析,请重新确认后再次对码`
          );
          return true;
        } else if (reg.test(item.num)) {
          this.Reg = 0;
          this.$message.error(
            `${item.customerProductName},数量表头内容包含中文无法解析,请重新确认后再次对码`
          );
          return true;
        } else {
          this.Reg = 1;
        }
      });
    },
    confirmFn() {
      let number = [];
      for (let i in this.comfrimFrom) {
        number.push(this.comfrimFrom[i]);
      }
      let request = [];
      if (this.fileList.length > 1) {
        this.fileList?.map((item, index) => {
          request.push({
            customerProductId: item[number[0]],
            customerProductBarcode: item[number[1]],
            customerProductApprovalNo: item[number[2]],
            customerProductName: item[number[3]],
            customerProductManu: item[number[4]],
            customerProductSpec: item[number[5]],
            price: item[number[6]],
            num: item[number[7]],
            salesmanName: item[number[8]],
          });
        });
      } else {
        let a = this.fileList[0];
        request.push({
          customerProductId: a[number[0]],
          customerProductBarcode: a[number[1]],
          customerProductApprovalNo: a[number[2]],
          customerProductName: a[number[3]],
          customerProductManu: a[number[4]],
          customerProductSpec: a[number[5]],
          price: a[number[6]],
          num: a[number[7]],
          salesmanName: a[number[8]],
        });
      }

      var newArr = [];
      var arrId = [];
      for (var item of request) {
        if (arrId.indexOf(item["customerProductId"]) == -1) {
          arrId.push(item["customerProductId"]);
          newArr.push(item);
        }
      }
      this.uploadFileList.productList = newArr;
      this.regFn();
    },
    //解析excel
    async uploadFile(params) {
      this.loading = true;
      const _file = params.file;
      const fileReader = new FileReader();
      const formData = new FormData();
      formData.append("file", _file);
      const { code, data } = await uploadAPI(formData, params);
      if (code === 200) {
        this.loading = false;
        this.uploadFileList.fileName = _file.name;
        this.uploadFileList.fileUrl = data.src;
        fileReader.onload = (ev) => {
          try {
            const data = ev.target.result;
            const workbook = XLSX.read(data, {
              type: "binary",
            });
            let key = 0;
            for (let sheet in workbook.Sheets) {
              const sheetArray = XLSX.utils.sheet_to_json(
                workbook.Sheets[sheet]
              );

              if (key === 0) {
                this.fileList = sheetArray;
                let selectkey = Object.keys(sheetArray[0]);

                let selectList = [];
                selectkey.forEach((item, index) => {
                  selectList.push({
                    name: item,
                    id: index,
                  });
                });
                this.options = selectList;
                this.dialogVisible = true;
              }
              key += 1;
            }
          } catch (e) {
            this.$message.warning("文件类型不正确！");
          }
        };
        fileReader.readAsBinaryString(_file);
      } else {
        this.loading = false;
      }
    },
    //删除列表对码商品
    removeList(item) {
      this.$confirm("是否确认删除该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        del({ id: item.id }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.getRightList();
          }
        });
      });
    },
    //新增导入
    addnewEXL() {
      this.addnewStatus = true;
      this.fromNew = true;
    },
    //导出商品
    downloadFile() {
      downLoadGoodBase({
        id: this.rightProductId,
        isOtrherCode: this.swichActive,
      }).then((result) => {
        if (result) {
          this.$message.success("正在下载当前列表");
        }
        this.getNowFormatTime();
        const contentType = "application/octet-stream;charset=UTF-8";
        const blob = new Blob([result], { type: contentType });
        const objectURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectURL;
        a.download = "云集药业" + this.nowTime + ".xlsx"; // 此处的savedName为保存到本地的文件名称及后缀
        a.click();
      });
    },
    completeDate(value) {
      return value < 10 ? "0" + value : value;
    },
    getNowFormatDay(nowDate) {
      var char = "-";
      if (nowDate == null) {
        nowDate = new Date();
      }
      var day = nowDate.getDate();
      var month = nowDate.getMonth() + 1; //注意月份需要+1
      var year = nowDate.getFullYear();
      //补全0，并拼接
      return (
        year + char + this.completeDate(month) + char + this.completeDate(day)
      );
    },
    getNowFormatTime() {
      let nowDate = new Date();
      let colon = ":";
      let h = nowDate.getHours();
      let m = nowDate.getMinutes();
      let s = nowDate.getSeconds();
      //补全0，并拼接
      this.nowTime =
        this.getNowFormatDay(nowDate) +
        " " +
        this.completeDate(h) +
        colon +
        this.completeDate(m) +
        colon +
        this.completeDate(s);
    },

    //一键加入购物车
    addCartAll() {
      if (!this.$store.state.AddrId) {
        this.$message.warning("请先选择收货地址");
        return;
      }
      this.$confirm("是否一键加入购物车?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",

        type: "warning",
      }).then(() => {
        let data = {
          customerAddrId: this.$store.state.AddrId,
          id: this.rightProductId,
        };
        this.buyloading = true;
        oneKeyShopAPI(data).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "已加入购物车",
              type: "success",
            });
            this.buyloading = false;
          } else {
            this.buyloading = false;
          }
        });
        setTimeout(() => {
          if ((this.buyloading = true)) {
            this.buyloading = false;
          }
        }, 15000);
      });
    },
    //加入购物车
    addcart(item) {
      if (!this.$store.state.AddrId) {
        this.$message.warning("请先选择收货地址");
        return;
      }
      let itemReqDTO = {
        productFromType: 1,
        customerAddrId: this.$store.state.AddrId,
        productId: item.goodsId,
        productQuantity: item.num,
      };
      addCartApi(itemReqDTO).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: "已加入购物车",
            type: "success",
          });
        }
      });
    },
    //人工取消excle
    cancelThis(item) {
      if (item.upDownShelfStatus !== "UP") {
        return;
      }
      this.$confirm("是否取消该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = {
          goodsBaseId: item.id,
          productId: item.productId,
          userId: this.$store.state.owerId,
        };
        cancelExcel(data).then((res) => {
          if (res.code == 200) {
            this.$message.success("取消商品成功");
            this.getRightList();
          } else {
            this.$message.warning(res.msg);
          }
        });
      });
    },

    //确认是该商品
    confirmThis(item, it) {
      if (it == 2) {
        if (item.upDownShelfStatus !== "UP") {
          return;
        }
        this.$confirm("是否确认是该商品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let data = {
            otrherCodeOrderItemId: item.id,
            productId: item.mallProductId,
          };

          manMade(data).then((res) => {
            if (res.code == 200) {
              this.$message.success("确认成功");
              this.getRightList();
            } else {
              this.$message.warning(res.msg);
            }
          });
        });
      } else {
        if (it.upDownShelfStatus !== "UP") {
          return;
        }
        this.$confirm("是否确认是该商品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let data = {
            otrherCodeOrderItemId: item.id,
            productId: it.mallProductDoc.mallProductId,
          };

          manMade(data).then((res) => {
            if (res.code == 200) {
              this.getRightList();
            } else {
              this.$message.warning(res.msg);
            }
          });
        });
      }
    },

    swichchange(index) {
      this.swichActive = index;
      this.getRightList();
    },
    //计数器数量修改
    changeBuyNum(item) {
      //    this.allPrice()
    },
    //左侧历史记录切换
    changeList(item, index) {
      this.fileName = item.fileName;
      this.options = item.excelMapList;
      this.duimaid = item.id;
      this.rightProductId = item.id;
      this.pageNum = 1;
      this.getRightList();
      this.active = item.id;
    },
    //查看更多
    checkMore(index, close) {
      if (close) {
        this.rightList[index].isshow = false;
        let anchor = document.getElementById(index);
        // chrome
        document.body.scrollTop = anchor.offsetTop;
        // firefox
        document.documentElement.scrollTop = anchor.offsetTop;
        // safari
        window.pageYOffset = anchor.offsetTop;
      } else {
        this.rightList[index].isshow = true;
      }

      this.$forceUpdate();
    },
    //获取右侧列表
    getRightList(ProductId, info) {
      this.loading = true;
      let data = {
        isPriceHigh: this.swichActive !== 3 ? this.swichActive : undefined,
        isOtrherCode: this.swichActive !== 3 ? 2 : 1,
        orderId: ProductId || this.rightProductId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        customerProductId: info?.customerProductId || undefined,
        customerProductName: info?.customerProductName || undefined,
        customerProductManu: info?.customerProductManu || undefined,
      };

      productListAPI(data).then((res) => {
        if (res.code == 200) {
          this.rightList = res.data.rows;
          this.rightList.map((itemm) => {
            itemm.show = false;
            if (itemm.erpIdList !== "") {
              let str = itemm.erpIdList.replace(/[\\]/g, "");
              itemm.goodsList = JSON.parse(str);
            }
            if (itemm.goodsList?.length > 0) {
              itemm.more = true;
              itemm.isshow = false;
            } else {
              itemm.more = false;
            }
          });
          this.total = res.data.total || 0;
          res.data.total
            ? (this.isnoneGoods = false)
            : (this.isnoneGoods = true);
        }
      });

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    search(serchinput) {
      this.getList(serchinput, "search");
    },

    //获取左边的列表
    getList(fileName, search) {
      let data = {
        baseType: 2,
        fileName: fileName || "",
        customerAddrId: this.uploadFileList.address.cusAddrId,
        pageNum: 1,
        pageSize: 10,
      };
      // axios({
      //     method: "POST",
      //     url: "http://yj.frp.nsusn.com:8000/goodsHeader/page",
      //     data: data,
      //   })
      orderListAPI(data).then(
        (res) => {
          if (res.code == 200) {
            if (search && res.data.total == 0) {
              this.rightList = [];
            } else if (res.data.total == 0) {
              this.addnewStatus = true;
            }
            this.leftList = res.data.rows;

            this.addnewStatus = false;
            this.rightProductId = res.data.rows[0].id;
            this.active = res.data.rows[0].id;
            this.duimaid = res.data.rows[0].id;
            this.options = res.data.rows[0].excelMapList;
            this.fileName = res.data.rows[0].fileName;
            this.getRightList(res.data.rows[0].id);
          }
        },
        (error) => {}
      );
    },

    //上传文件
    uploadFileSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.duimaList = response.data;
        response.data.excelMapList.map((item) => {
          item.label = item.name;
          item.value = item.id;
        });
        this.duimaid = response.data.id;
        this.options = response.data.excelMapList;
        this.dialogVisible = true;
      } else {
        this.$message.warning(response.msg);
      }
    },
    beforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["xls", "xlsx"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error("上传文件只能是 xls、xlsx格式");
        return false;
      }
      const isLt30M = file.size / 1024 / 1024 < 10;
      if (!isLt30M) {
        this.$message.error("上传文件大小不能超过 10MB");
        return false;
      }
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getRightList();
    },
    handleCurrentChange(e) {
      this.pageNum = e;
      this.getRightList();
      window.scrollTo({
        left: 0,
        top: 300,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/base.less";
::v-deep {
  el-upload el-upload--text {
    width: 100%;
  }
  .el-upload {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .upload-demo {
    width: 100%;
  }
  .el-upload-dragger {
    background: #f7f8fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96%;
    height: 321px;
  }
  .el-progress__text {
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    color: #26c487;
  }
  .el-progress--text-inside .el-progress-bar {
    margin-top: 5px;
    margin-right: 0;
  }
  .el-progress-bar__outer {
    border-radius: 10px !important;
    height: 10px;
    line-height: 10px;
  }
  .el-progress-bar__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  -input-number__decrease,
  .el-input-number--mini .el-input-number__increase {
    width: 25px;
    font-size: 12px;
  }
  .el-input-number--mini {
    width: 80px;
  }
  .el-icon-plus:before {
    color: @green;
  }

  .el-input-number__decrease {
    left: 1px;
    border-radius: 4px 0 0 4px;
    border-right: 0;
  }
  .el-input-number__decrease,
  .el-input-number__increase {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 4px;
    text-align: center;
    border: 1px #f5f5f5 solid;
    background: #fff;

    cursor: pointer;
    font-size: 13px;
  }
  .el-input-number--mini .el-input__inner {
    border: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
  .el-input-number__increase {
    color: @green;
    right: 1px;
    border-radius: 0 4px 4px 0;
    border-left: 0;
  }

  .el-progress-circle {
    width: 74px !important;
    height: 74px !important;
  }
  .el-progress-bar__outer {
    border-radius: 0;
  }
  .el-progress-bar__inner {
    background-color: #26c487;
    border-radius: 0px;
  }
  .el-button {
    border-radius: 0px;
  }

  .el-button {
    border-radius: 0px;
  }
  .el-button--success {
    color: #fff;
    background-color: #26c487;
    border-color: #26c487;
  }
  .el-button--primary {
    color: #fff;
    background-color: #26c487 !important;
    border-color: #26c487 !important;
  }

  .el-input-group__append button.el-button,
  .el-input-group__append div.el-select .el-input__inner,
  .el-input-group__append div.el-select:hover .el-input__inner,
  .el-input-group__prepend button.el-button,
  .el-input-group__prepend div.el-select .el-input__inner,
  .el-input-group__prepend div.el-select:hover .el-input__inner {
    border-color: transparent;
    background-color: #26c487;
    color: inherit;
    border-top: 0;
    border-bottom: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-select {
    width: 300px;
  }
}
.more {
  padding: 20px;
}
.more:hover {
  color: @green;
}
.red {
  color: red;
}
.cancel {
  font-size: 12px;
  font-family: 'regular';
  font-weight: 400;
  color: #646464;
}
.uploadbtn {
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'regular';
  font-weight: 400;
  color: #333333;
  border: 1px solid #e7e8eb;
}
.flex-between {
  padding: 30px;
  background-color: #fff;
}
.banxin {
  background-color: #f2f2f2 !important;
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.swich {
  margin-right: 20px;
  padding-bottom: 8px;
  line-height: 100%;
  height: 32px;
  font-size: 16px;
  cursor: pointer;
  padding: 0 24px;
  text-align: center;
  box-sizing: border-box;
}
.switchactive {
  font-size: 16px;
  font-family: 'medium';
  font-weight: 500;
  color: #26c487;
  border-bottom: 4px solid #26c487;
}
.su {
  line-height: 40px;
  margin: 10px 20px;
  width: 1px;
  height: 16px;
  background: #f5f5f5;
}
.active {
  border: 1px solid @green !important;
}
.bd {
  border-top: 1px solid @hui;
  border-bottom: 1px solid @hui;
}
.tac {
  color: #adafad;
}
.ml0 {
  margin-left: 0;
}
.fl {
  flex: 1;
}
.dia {
  .txt {
    font-weight: 500;
    margin-top: 20px;
    line-height: 1.5;
  }
  .biao {
    padding: 0 40px 0 40px;
  }
  .tabel {
    .xin {
      margin-top: 20px;
      margin-bottom: 20px;
      align-items: center;
      width: 100%;
      display: flex;
      .left_name {
        font-weight: 600;
        width: 30%;
      }
    }
  }
}
.upload-demo {
  text-align: center;
}
.upload {
  width: 100%;
  padding-bottom: 30px;
  margin-bottom: 30px;
  background-color: #fff;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.body {
  background-color: #f2f2f2;
  padding-top: 28px;
}
.list {
  width: 100%;
  display: flex;
  .por {
    position: relative;
  }
  .poab {
    box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.03);
    border: 1px solid #f5f5f5;
    padding: 25px;
    position: absolute;
    display: flex;
    color: #333;
    z-index: 99;

    top: 30px;
    line-height: 20px;
    font-size: 14px;
    right: -5px;
    background: #fff;
  }
  .topab:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 8px solid #fff;
    border-bottom-color: #fff;
    right: 50px;
    bottom: 100%;
    margin-left: -15px;
  }
  .right_box {
    background-color: #fff;
    flex: 1;
  }
  .by {
    padding: 0 20px;
    height: 100%;
    padding: 0 20px;
    border-left: 1px solid #f5f5f5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid #f5f5f5;
  }
  .goodsDetail {
    text-align: center;
    margin-top: 65px;
    height: 100%;
    margin-left: 16px;
    display: flex;
    height: 100%;
    flex-direction: column;

    .info {
      text-align: center;
      font-size: 12px;
      font-family: 'regular';
      font-weight: 400;
      color: #26c487;
    }
  }
  .manufacturer {
    max-width: 200px;
  }
  .h1 {
    line-height: 1.5;
    font-size: 12px;
    font-family: 'regular';
    font-weight: 400;
    color: #131313;
    margin: 10px 0;
  }
  .cardlist {
    margin-left: 20px;
    display: flex;
    box-sizing: border-box;
    .lii {
      margin-top: 5px;
      display: flex;
      .card {
        position: relative;
        text-align: center;
        border: 1px solid @hui;
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        .isdown {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .lef {
      flex: 1;
    }
    .righ {
      height: 20px;
      line-height: 20px;
      justify-content: flex-end;
    }
    .fo {
      padding-bottom: 30px;
      border-bottom: 1px dashed @hui;
    }
  }
  .none {
    margin: 35% auto;
    text-align: center;
    color: #adafad;
    font-size: 18px;
  }
  .nogoods {
    margin: 35% auto;
    text-align: center;
    color: #707070;
    font-size: 18px;
  }
}
.barcodeScore {
  font-size: 12px;
  margin-top: 7px;
  font-family: 'regular';
  font-weight: 400;
}
</style>
