<template>
  <div class="hover-card">
    <div class="img">
      <el-image
        style="width: 130px; height: 120px"
        :src="item.mallAttachUrl || emptyImg"
        fit="cover"
      ></el-image>
    </div>
    <div class="content">
      <div class="title">
        <div class="titel-name">{{ item.mallProductName }}</div>
        <div class="tips">
          <div
            :class="
              item.mallSalePrice - item.customerProductPrice < 0
                ? ' upPrice fred fs12'
                : 'gr downPrice fs12'
            "
          >
            <img
              class="icon"
              :src="
                item.mallSalePrice - item.customerProductPrice < 0
                  ? reducedPrice
                  : risePrice
              "
              alt
            />
            ￥{{
              Math.abs(item.mallSalePrice - item.customerProductPrice).toFixed(
                2
              ) || item.mallSalePrice
            }}
          </div>
        </div>
      </div>

      <div class="content-txt">
        <span>69码：{{ item.mallProductBarcode }}</span>
        <span>平台商品编码：{{ item.mallCode }}</span>
        <span>国药准字：{{ item.mallProductApprovalNo }}</span>
        <span>厂家名称：{{ item.mallProductManu }}</span>
      </div>
      <div class="content-tag">
        <div class="tag">
          件装量:{{ item.mallPackSpec }}{{ item.mallUnit }}/件
        </div>
        <div class="tag">规格:{{ item.mallProductSpec }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {},
  data() {
    return {
      emptyImg: require("../../../assets/images/empty-product.jpg"),
      risePrice: require("../../../assets/images/quicklyOrder/shang.png"),
      reducedPrice: require("../../../assets/images/quicklyOrder/xia.png"),
    };
  },
};
</script>

<style lang="less" scoped>
.upPrice {
  background: rgba(227, 50, 51, 0.08);
  padding: 2px 5px;
}
.downPrice {
  padding: 2px 5px;
  background: rgba(38, 196, 135, 0.08);
}
.hover-card {
  float: left;
  position: absolute;
  top: 50px;
  z-index: 2;
  box-sizing: border-box;
  display: inline-flex;
  //   height: 160px;
  padding: 20px 12px 20px 20px;
  background: #edfaec;
  opacity: 1;
  border: 1px solid #26c487;
  .content {
    margin-left: 12px;
    .title {
      margin-top: 7px;
      font-size: 12px;
      display: flex;
      align-items: center;
      .titel-name {
        font-weight: bold;
        color: #333333;
        margin-right: 20px;
      }
    }
    .content-txt {
      margin-top: 11px;
      font-size: 10px;
      color: #646464;
      display: flex;
      flex-direction: column;
      span {
        &:first-child {
          margin-top: 0;
        }
        font-size: 10px;
        margin-top: 5px;
        display: block;
      }
    }
    .content-tag {
      margin-top: 8px;
      display: flex;
      .tag {
        white-space: nowrap;
        margin-right: 14px;
        padding: 4px 7px;
        font-size: 8px;
        color: #a19c9c;
        background: #efefef;
      }
    }
  }
}
</style>
