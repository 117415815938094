<template>
  <div v-loading="loading" class="ConfrimShopping">
    <div class="body">
      <div class="banxin">
        <div class="tt df">
          <div class="suxian"></div>
          确认订单信息
        </div>
        <div v-if="isnoe" class="none">
          <img src="@/assets/images/kongList.png" alt="" />
          <div class="gobuy">
            暂无商品，
            <span @click="$router.push('/home')" class="cup gr"
              >去首页下单>
            </span>
          </div>
        </div>
        <div v-for="(item, index) in goodsList" :key="index" class="userlist">
          <div style="background: #26c487" class="tanxin">
            <div class="head left">
              <div class="div df aic" style="color: #fff">
                <img
                  style="width: 16px; height: 16px"
                  class="icon mr10"
                  src="@/assets/images/cart/baiman.png"
                  alt
                />
                <span class="mr20 fs14 fw600">客户{{ index + 1 }}</span>
                {{ item.customerName }}
              </div>
              <div class="su"></div>
              <div class="div aic df" style="color: #fff">
                <img
                  style="width: 14px; height: 16px"
                  class="icon mr10"
                  src="@/assets/images/cart/lock.png"
                  alt
                />
                <span
                  style="text-overflow: ellipsis;
                    max-width: 300px;
                    font-size: 14px;
                    overflow: hidden;
                  "
                  class="mr20 fs14 fw600"
                >
                  {{ item.takeName || item.receiveAddress }}
                </span>
                {{ item.takePhone || item.receivePhone }}
                <div
                  style="margin-left: 10px; color: #fff; font-size: 12px"
                  class="song fs14 df aic"
                >
                  {{ item.receiveType == 1 ? "送货上门" : "到仓自提" }}
                </div>
              </div>
            </div>
            <div class="head right">
              <div
                v-if="item.receiveType == 2"
                @click="showtoast = true"
                @mouseleave="showtoast = false"
                class="div cup aic por df"
                style="color: #fff"
              >
                <div>
                  <img
                    style="width: 13px; height: 13px; margin-right: 5px"
                    class="icon"
                    src="@/assets/images/cart/wenhao.png"
                    alt
                  />
                  提示
                </div>
                <div v-show="showtoast" class="poab topab">
                  <div style="width: 38%; color: #9e9fab">
                    <i style="color: #ffa936" class="el-icon-warning"></i>
                    温馨提示：
                  </div>
                  <div>
                    提货时需携带提货本人身份证；如委托他人提货，则需带上提货
                    委托书并加盖药房公章（鲜章）以及提货人本人身份证
                  </div>
                </div>
              </div>

              <!-- <div  v-if="item.receiveType ==2" class="su"></div>
              <div  v-if="item.receiveType ==2" class="div cup aic df" style="color: #fff">
                <img
                  style="width: 13px; height: 13px"
                  class="icon mr10"
                  src="@/assets/images/cart/tihuo.png"
                  alt
                />
                提货委托书
              </div> -->
              <div v-if="item.receiveType == 2" class="su"></div>

              <div
                @click="removeCastom(index)"
                class="div cup aic df"
                style="color: #fff"
              >
                <img
                  style="width: 13px; height: 15px"
                  class="icon mr10"
                  src="@/assets/images/cart/sahnchu.png"
                  alt
                />
                移除客户
              </div>
            </div>
          </div>
          <div class="cartList">
            <div class="list">
              <el-table
                :key="keys"
                :header-cell-style="{ background: '#F8F8F8' }"
                :header-row-style="{ fontWeight: 400 }"
                ref="multipleTable"
                :data="item.productList"
                tooltip-effect="dark"
                style="width: 100%"
              >
                <el-table-column label="商品信息" width="400">
                  <template slot-scope="scope">
                    <Product-Card :isCart="true" :rows="scope.row">
                    </Product-Card>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="spec"
                  class-name="fw400"
                  align="center"
                  label="规格"
                  width="auto"
                ></el-table-column>
                <el-table-column
                  prop="productPrice"
                  align="center"
                  label="单价(元)"
                  width="auto"
                >
                  <template slot-scope="scope">
                    <span
                      class="apprice"
                      style="color: #333; font-size: 12px"
                      >{{
                        scope.row.shopType == 2 && scope.row.purPlanFlag == 1
                          ? "待定价"
                          : scope.row.priceFlag == 1 && scope.row.shopType == 2
                          ? scope.row.productPrice
                          : scope.row.shopType == 2 && scope.row.priceFlag == 0
                          ? "待定价"
                          : scope.row.productPrice
                      }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="productQuantity"
                  align="center"
                  label="数量"
                  width="150"
                >
                  <template slot-scope="scope">
                    <el-input-number
                      class="input-number"
                      size="small"
                      step-strictly
                      :step="scope.row.minSales"
                      :min="1"
                      @change="changeBuyNum(scope.row)"
                      v-model="scope.row.productQuantity"
                    ></el-input-number>
                    <div
                      :style="scope.row.status !== 'UP' ? 'color: #333' : ''"
                      class="gr mt5 fs12"
                    >
                      {{
                        scope.row.shopType == 1 && scope.row.stock == 0
                          ? ""
                          : "库存"
                      }}
                      {{
                        scope.row.pchao && scope.row.shopType == 1
                          ? scope.row.stock + "件"
                          : scope.row.shopType == 1 && scope.row.stock == 0
                          ? "代采"
                          : scope.row.stock + "件"
                      }}
                    </div>
                    <div
                      :style="
                        scope.row.stock &&
                        scope.row.productQuantity > scope.row.stock
                          ? 'color:#e33233'
                          : 'display:none'
                      "
                      class=" fs12"
                    >
                      超出库存!
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  width="auto"
                  align="center"
                  prop="price"
                  label="小计(元)"
                >
                  <template slot-scope="scope">
                    {{
                      scope.row.shopType == 2 && scope.row.priceFlag == 1
                        ? (
                            scope.row.productPrice * scope.row.productQuantity
                          ).toFixed(2)
                        : scope.row.shopType == 2
                        ? "待定价"
                        : (
                            scope.row.productPrice * scope.row.productQuantity
                          ).toFixed(2)
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  fixed="right"
                  align="center"
                  label="操作"
                  width="150"
                >
                  <template slot-scope="scope">
                    <div
                      @click="removeGoods(scope.row.id, item)"
                      class="fhui fw400 action-button"
                      type="text"
                      size="small"
                    >
                      移除
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="youhui mt20">
            <div class="select aic df">
              <div class="fs16 mr20">备注</div>
              <!-- <el-select v-model="Selectvalue" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                ></el-option>
              </el-select> -->
              <div v-if="item.remark" class="info-box">{{item.remark}}</div>
              <div class="gr cursor" @click="editRemark(index,item)">{{ item.remark ? '修改备注' : '添加备注' }}</div>
            </div>
            <div style="margin-top: 0" class="all">
              <li>
                <div class="fs14 fhui">商品总数量</div>
                <div class="fw600 fred black">{{ item.totalQuantity }}</div>
              </li>
              <li style="margin-bottom: 0">
                <div class="fs14 fhui">商品总金额</div>
                <div class="fw600 fred black">¥ {{ item.totalAmt }}</div>
              </li>
            </div>
          </div>
        </div>

        <div class="all">
          <li>
            <div class="fs14 fhui">商品总数量</div>
            <div class="fw600 fred black">{{ totalQuantity }}</div>
          </li>
          <li>
            <div class="fs14 fhui">商品总金额</div>
            <div class="fw600 fred black">¥ {{ totalAmt }}</div>
          </li>
          <li>
            <div class="fs14 fhui">应付总金额</div>
            <div class="fw600 fred">¥ {{ payAmt }}</div>
          </li>
          <li style="margin-top: 32px" class="aic">
            <el-button class="mr10" @click="$router.back()">取消订单</el-button>

            <el-button
              :disabled="disabled"
              @click="goPay"
              :type="disabled ? '' : 'success'"
              >全部支付</el-button
            >
          </li>
          <li style="margin-top: 32px" class="aic">
            <div class="hui wenxing">
              <div class="fs14 fhui">
                <i class="el-icon-warning" style="color: #ffa936"></i>
                温馨提示：采购下单代表你已阅读并同意
                <span @click="read" class="cup gr">《慧金采平台服务协议》</span>
              </div>
            </div>
          </li>
        </div>
      </div>
    </div>

    <!-- 控销商品弹框 -->
    <div class="paylog">
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="confirmDialog"
        width="360px"
      >
        <div class="df">
          <i
            style="color:#FAAD14;margin-right:10px; line-height: 1.5;"
            class="el-icon-warning"
          ></i>
          <div>
            当前<span class="gr">{{ addOrderList.planNum }}</span
            >个控销商品处于‘审批中’状态,可“<span @click="checkout" class="gr cup"
              >点击查看</span
            >”
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            v-if="addOrderList.orderNum > 0"
            type="primary"
            @click="continuePay"
            >继续支付{{ payTimes }}s</el-button
          >
          <el-button
            v-else
            @click="checkout"
            >查看订单</el-button
          >
        </span>
      </el-dialog>
    </div>

    <!-- 备注弹窗 -->
    <el-dialog :visible.sync="remarkVisible" width="480px">
      <div slot="title">
        <div class="remark-title">{{remark ? '修改备注' : '添加备注'}}</div>
      </div>
      <el-input v-model="remark" type="textarea" :rows="3" placeholder="请输入内容" maxlength="200" show-word-limit></el-input>
      <span slot="footer">
        <el-button @click="toggleRemark(0)">取 消</el-button>
        <el-button type="primary" @click="toggleRemark(1)">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateCart, deleteCartItemIos } from "@/request/CartApi/cart";
import { orderConfList, addOrder } from "@/request/Orders/order";
import { findProductList } from "@/request/CartApi/cart";

import ProductCard from "@/components/custom-base/custom-product-card";
export default {
  components: {
    ProductCard,
  },
  watch: {
    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue.params.data) {
          this.Listdata = newValue.params.data;
          localStorage.setItem(
            "Listdata",
            JSON.stringify(newValue.params.data)
          );
        } else {
          this.Listdata = JSON.parse(localStorage.getItem("Listdata"));
        }
      },
    },
  },
  beforeDestroy() {
    localStorage.removeItem("Listdata");
  },
  data() {
    return {
      timer: null,
      addOrderList: {},
      payTimes: 5,
      confirmDialog: false,
      keys: 0,
      loading: false,
      //订单列表为空
      isnoe: false,
      disabled: false,
      Listdata: [],
      customerId: "",
      //温馨提示
      showtoast: false,
      options: [],
      Selectvalue: "",
      payAmt: "",
      totalAmt: "",
      totalQuantity: "",
      //商品列表
      goodsList: [],
      remarkVisible: false, // 备注弹窗
      currentIndex: null, 
      remark: ''
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    this.getList();
    this.allPrice();
  },
  methods: {
    //判是否为空
    peoductNull() {
      if (this.goodsList.length == 0) {
        this.isnoe = true;
        this.payAmt = 0;
        this.totalAmt = 0;
        this.totalQuantity = 0;
        this.goodsList = [];
        this.disabled = true;
      }
    },
    //移除客户
    removeCastom(index) {
      if (this.goodsList.length == 1) {
        this.$message.warning("订单至少保留一个客户");
        return;
      }
      this.goodsList.splice(index, 1);
      this.peoductNull();
    },
    //阅读规范
    read() {
      //  window.location.href='../../../public/agreement.html'
      window.open(`/agreement.html`);
    },
    //移除商品
    removeGoods(id, item) {
      if (item.productList.length <= 1) {
        this.$message.warning("至少保留一条商品");
        return;
      }
      this.$confirm("是否确认移除商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (id) {
          let arrid = [];
          arrid.push(id);
          deleteCartItemIos({ cartItemId: arrid }).then((res) => {
            if (res.code === 200) {
              this.getList();
              this.$message({
                type: "success",
                message: "移除成功",
              });
            }
          });
        }
      });
    },
    checkout() {
      clearInterval(this.timer);
      this.$router.push("/user/myOrder");
    },
    continuePay() {
      clearInterval(this.timer);
      this.$router.push({
        path: "/newPay",
        query: { checkPay: this.addOrderList.orderIds },
      });
    },
    //去支付
    goPay() {
      this.loading = true;
      let confrim = [];
      this.goodsList.map((item) => {
        confrim.push({
          cartId: item.productList[0].cartId,
          receiveType: item.receiveType,
          sourceType: 0,
          takeEntrust: item.takeEntrust,
          takeIdentityNum: item.takeIdentityNum,
          takeName: item.takeName,
          takePhone: item.takePhone,
          remark: item.remark
        });
      });

      addOrder(confrim).then((res) => {
        if (res.code == 200) {
          this.addOrderList = res.data;
          this.loading = false;
          findProductList({
            customerId: this.$store.state.AddrId,
          }).then((response) => {
            if (response.code === 200) {
              let productNum = response.data.productList.length;
              this.$store.commit("setProductNum", productNum);
            }
          });
          if (res.data.planNum > 0) {
            this.confirmDialog = true;
            if(res.data.orderNum > 0){
              this.timer = setInterval(() => {
                if (this.payTimes <= 1) {
                  this.$router.push({
                    path: "/newPay",
                    query: { checkPay: res.data.orderIds },
                  });
                  clearInterval(this.timer);
                }
                this.payTimes--;
              }, 1000);
            }
          }else{
            this.$router.push({
              path: "/newPay",
              query: { checkPay: res.data.orderIds },
            });
            clearInterval(this.timer);
          }
        } else {
          this.loading = false;
        }
        setTimeout(() => {
          if ((this.loading = true)) {
            this.loading = false;
          }
        }, 15000);
      });
    },
    //获取列表
    getList() {
      orderConfList(this.Listdata).then((res) => {
        if (res.code === 200) {
          this.goodsList = res.data.cusGoodsList;
          this.payAmt = res.data.payAmt;
          this.totalAmt = res.data.totalAmt;
          this.totalQuantity = res.data.totalQuantity;
          this.isnoe = false;
          this.disabled = false;
        } else {
          // this.isnoe=true
          this.goodsList = [];
          //空状态
          this.peoductNull();
        }
      });
    },
    allPrice() {
      var totalPrice1 = 0;
      var totalNum1 = 0;
      this.goodsList.forEach((item) => {
        totalPrice1 += item.productPrice * item.productQuantity;
        totalNum1 += Number(item.num);
      });
      this.totalPrice = totalPrice1;
      this.totalNum = totalNum1;
    },

    changeBuyNum(row) {
      if (row.productQuantity % row.minSales !== 0) {
        row.productQuantity =
          row.minSales * Math.floor(row.productQuantity / row.minSales);
        this.keys++;
      }
      if (row.shopType == 2 && row.productQuantity > row.stock) {
        this.$message.warning("购买数量不能大于库存");
        row.productQuantity =
          row.minSales * Math.floor(row.stock / row.minSales);
        return;
      }
      updateCart({
        cartId: row.cartId,
        itemId: row.id,
        productQuantity: row.productQuantity,
      }).then((res) => {
        this.getList();
      });

      this.allPrice();
    },

    // 修改备注
    editRemark(index,item) {
      this.currentIndex = index
      this.remarkVisible = true;
      if (item.remark) this.remark = item.remark
    },
    toggleRemark(type) {
      if(type === 1) {
        this.goodsList[this.currentIndex].remark = this.remark
      }
      this.remarkVisible = false
      this.remark = ''
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/base.less";
::v-deep {
  .paylog {
    .el-dialog__header {
      display: none;
    }
    .el-dialog {
      position: relative;
      margin: 0 auto 50px;
      padding: 0 30px;
      background: #fff;
      border-radius: 2px;
      line-height: 1.5;
      /* box-shadow: none; */
      box-sizing: border-box;
    }
  }
  .el-table td.el-table__cell div {
    color: #131313;
    box-sizing: border-box;
  }
}
.gr {
  color: #26c487 !important;
}
.black {
  color: #333;
}
.fhui {
  color: #9e9fab;
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  .gobuy {
    color: #707070;
    font-size: 18px;
    margin-top: 40px;
  }
}
.por {
  position: relative;
}
.poab {
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.03);
  border: 1px solid #f5f5f5;
  padding: 20px;
  position: absolute;
  display: flex;
  color: #333;
  z-index: 99;
  width: 500px;
  top: 30px;
  line-height: 20px;
  font-size: 14px;
  right: -5px;
  background: #fff;
}
.topab:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid @green;
  border-bottom-color: #fff;
  right: 40px;
  bottom: 100%;
  margin-left: -15px;
}
.all {
  margin-top: 20px;
  background-color: #f7f8fa;
  padding: 15px 20px;
  li {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .hui {
      text-align: right;
      flex: 1;
    }
    .fred {
      text-align: right;
      width: 10%;
    }
  }
}
.youhui {
  margin-bottom: 30px;
  box-sizing: border-box;
  /* height: 196px; */
  border: 1px solid #e7ebe7;
  .select {
    padding: 15px 20px;
    .info-box {
      max-width: 400px;
      margin-right: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .all {
    margin-top: 20px;
    background-color: #f7f8fa;
    padding: 15px 20px;
    li {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
      .hui {
        text-align: right;
        flex: 1;
      }
      .fred {
        text-align: right;
        width: 10%;
      }
    }
  }
}
.list {
  border: 1px solid @hui;
}
.cardlist {
  display: flex;
  box-sizing: border-box;
  .lii {
    display: flex;
    .card {
      text-align: center;
      border: 1px solid @hui;
      width: 100px;
      height: 100px;
      box-sizing: border-box;
      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .goodsDetail {
      flex: 1;
      margin-left: 16px;
      line-height: 1.5;
      color: #131313;
      .h1 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .info {
        flex: 0 0 120px;
        white-space: pre-wrap;
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .lef {
    flex: 1;
  }
  .righ {
    height: 20px;
    line-height: 20px;
    justify-content: flex-end;
  }
  .fo {
    padding-bottom: 30px;
    border-bottom: 1px dashed @hui;
  }
}
.song {
  height: 22px;
  line-height: 22px;
  padding: 2px 9px;
  border: #fff 1px solid;
  opacity: 0.45;
}
.body {
  background-color: @body;
  padding-top: 20px;
}
.banxin {
  box-sizing: border-box;
  background-color: #fff;
  padding: 30px;
}
.su {
  line-height: 20px;
  margin: 0 20px;
  width: 1px;
  height: 20px;
  background: #fff;
  opacity: 0.3;
  border-radius: 1px;
}
.head {
  padding: 0 30px;
  align-items: center;
  display: flex;
  min-height: 60px;
  line-height: 1.2;
  background: #26c487;
  border-radius: 1px 1px 1px 1px;
  opacity: 1;
  border: 1px solid #26c487;
}
.cartList {
  .action-button {
    color: #707070;
    font-weight: 400;
    &:hover {
      color: #26c487;
      cursor: pointer;
    }
  }
}

::v-deep .el-dialog__header {
  background: #F7F8FA;
  padding-bottom: 20px;
}
.remark-title {
  font-size: 20px;
  font-family: 'medium';
  font-weight: 500;
  color: #202D40;
  height: 20px;
  padding-left: 10px;
  border-left: 5px solid #26C487;
}
</style>
